import { useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Menu, Transition } from "@headlessui/react";
import { useTheme } from "../../../../theme/themeContext";
import { useTranslation } from "react-i18next";
import VoipHawkLogo from "../../../../utils/icons/VoIPHawk Logo.png";
import jwtDecode from "jwt-decode";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { getENVData } from "../../../../config/environment";
import { useDispatch, useSelector } from "react-redux";
import { setUserRoles } from "../../../../toolkit/reducers/authSlice";
import logout from "../../../../assets/logout.svg";
import { getRoles, getTenantDevice, getTenantDevices } from "../../../../services/api";
import Logo from "../../../../assets/Netstratum- logo.svg"
import Notification from "../../../../assets/notification.svg"
import { TBClient } from "../../../../config/thinksboard";
import axios from "axios";
import { setData, setNetworkError, setRelatonalTable } from "../../../../toolkit/reducers/thingsBoardSlice";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
const ToolBar = () => {
  const { theme, toggleTheme } = useTheme();
  const [currentLanguage, setCurrentLanguage] = useState<any>({
    name: "English",
    key: "en",
  });
  const dispatch = useDispatch();
  const bargeData = useSelector((state: any) => state.thingsBoard.fullData);
  const fullBargeData = useSelector((state: any) => state.thingsBoard.data);
  const relationtable = useSelector((state: any) => state.thingsBoard.relationTable);
  const networkError = useSelector((state: any) => state.thingsBoard.networkError);
  const [timerCount, setTimerCount] = useState(0)
  // const [activeAccount, setActiveAccount] = useState('');
  const GetFullData = async () => {
    const Fulldata: any = await getTenantDevice(TBClient.token);
    // debugger;
    if (Fulldata?.length > 0) {
      WebsocketData(Fulldata)
    }
  }
  useEffect(() => {
    getTenantDevices(dispatch, TBClient.token);
  }, [])

  useEffect(() => {
    const tim = setInterval(() => {
      setTimerCount(timerCount => timerCount + 1)
    }, 300000);
    return () => {
      window.clearInterval(tim);
    };

  }, [])


  useEffect(() => {
    if (Object.keys(fullBargeData).length > 0) {
      console.log("Trying####", fullBargeData)
      GetFullData()
    } else {
      console.log("Trying####failed", fullBargeData)

    }
  }, [timerCount])
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [websocketArray, setWebsocketArray] = useState([]);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openMemberModal, setOpenMemberModal] = useState(false);
  const [addMenu, setAddMenu] = useState(false);
  const environment = getENVData();
  const { keycloak } = useKeycloak();

  const [timezone, setTimeZone] = useState([
    {
      type: "timezone",
      layout: 5,
      label: "TimeZone",
      key: "timezone",
      title: "Timezone",
      value: null,
      default: null,
    },
  ]);

  const [fromDate, setFromDate] = useState([
    {
      key: "from",
      type: "date",
      title: "from",
      layout: 2,
      value: null,
      default: null,
    },
  ]);
  const [toDate, setToDate] = useState([
    {
      key: "to",
      type: "date",
      title: "to",
      layout: 2,
      value: null,
      default: null,
    },
  ]);

  // const [user, setUser] = useState<any>({});
  const languages = [
    {
      name: "English",
      key: "en",
    },
    {
      name: "French",
      key: "fr",
    },
    {
      name: "Spanish",
      key: "sp",
    },
  ];
  const changeTheme = () => {
    toggleTheme(theme === "light" ? "dark" : "light", () => { });
  };
  // const navigateTo = (nav: string) => {
  //   navigate(`/${nav}`, { replace: true });
  // };
  const handleRoles = (userRoles: any) => {
    setTimeout(() => {
      let payload: any = {};
      if (userRoles.length === 1) {
        payload = {
          uuid: userRoles[0].uuid ? userRoles[0].uuid : userRoles[0],
        };
      }
      getRoles(payload, { skipHeader: false }).then((result) => {
        if (result.length > 0) {
          dispatch(setUserRoles(result));
        } else {
          dispatch(setUserRoles(false));
          console.error("ERROR: No user roles Found");
        }
      });
    }, 200);
  };
  const handleLogOut = async () => {
    if (environment.env === "localhost") {
      // localStorage.clear();
      window.localStorage.removeItem("currentAccount");
    } else {
      window.localStorage.removeItem("currentAccount");
    }
    await keycloak.logout();
    navigate(`/app`, { replace: false });
  };

  useEffect(() => {
    if (keycloak?.token) {
      const token: any = jwtDecode(keycloak?.token);
      console.log("keycloak token", token);
      // setUser(token);
      const currentAccount: any = window.localStorage.getItem("currentAccount");
      // const token_display_name = token?.account_type === "super_telco" ? token.azp : token?.display_name ? token?.display_name : token?.name;
      if (currentAccount) {
        console.log("JWT TOKEN", token);
        const data: any = JSON.parse(currentAccount);
        let acc = data.account_type ? data.account_type : data.type;
        // const display_name = acc === "super_telco" ? data.azp : data?.display_name ? data?.display_name : data?.name;
        // setActiveAccount(display_name)
        acc = acc === "super_telco" ? "super" : acc ? acc : "tenant";
        let payload = { name: `${acc}_admin` };
        if (data?.email === "voifinity@netstratum.com") {
          payload = { name: `super_admin` };
          // setActiveAccount(display_name)
        }
        setTimeout(() => {
          getRoles(payload).then((result) => {
            dispatch(setUserRoles(result));
          });
        }, 200);
      } else if (token?.email === "voifinity@netstratum.com") {
        let payload = { name: `super_admin` };
        // setActiveAccount(token_display_name)
        setTimeout(() => {
          getRoles(payload).then((result) => {
            dispatch(setUserRoles(result));
          });
        }, 200);
      } else if (token?.realm_settings?.roles) {
        // setActiveAccount(token_display_name)
        handleRoles(token.realm_settings.roles);
      } else {
        // setActiveAccount(token_display_name)
        dispatch(setUserRoles(false));
        console.error("ERROR: No user roles Found");
      }
    }
  }, [keycloak]);
  const initClientToken = () => {
    if (keycloak?.token) {
      const token: any = jwtDecode(keycloak?.token);
      const currentAccount: any = window.localStorage.getItem("currentAccount");
      if (!currentAccount) {
        localStorage.setItem("currentAccount", JSON.stringify(token));
      }
    }
  };
  useEffect(() => {
    initClientToken();
  }, []);



  //barge-monitor Websocket
  const [relationData, setRelationData] = useState<any>({})



  useEffect(() => {
    if (bargeData.length > 0) {
      WebsocketCall()
    }
    if (Object.keys(fullBargeData).length > 0 && Object.keys(relationData).length === 0) {
      setRelationData(JSON.parse(JSON.stringify(fullBargeData)))
    }
  }, [bargeData])
  const WebsocketData = (Fulldata: any) => {
    var barge: any = JSON.parse(JSON.stringify(relationData))
    Object.keys(barge).map((node: any) => {
      Object.keys(barge[node]).map((cabins: any) => {
        barge[node][cabins].map((devices: any, index: number) => {

          if (Fulldata?.length === 0) {

            const filterData = relationtable.filter((obj: any) => obj.entityId === devices.id.id)
            if (filterData.length > 0) {

              websocketArray.map((data: any) => {
                if (data.subscriptionId === filterData[0].cmdId) {
                  if (barge[node][cabins][index].websocket) {
                    if (Object.keys(barge[node][cabins][index].websocket.data).length > 0) {
                      Object.keys(barge[node][cabins][index].websocket.data).map((key: any) => {
                        if (data.data[key]) {
                          barge[node][cabins][index].websocket.data[key] = data.data[key]
                        }
                      })
                    }
                    if (Object.keys(barge[node][cabins][index].websocket).length > 0) {
                      Object.keys(barge[node][cabins][index].websocket).map((key: any) => {
                        if (key !== 'data' && data[key]) {
                          barge[node][cabins][index].websocket[key] = data[key]
                        }
                      })
                    }

                  } else {
                    barge[node][cabins][index].websocket = data
                  }

                  if ((data.data?.data_leakage_status && data.data?.data_leakage_status[0][1] === 'leak') || (data.data?.data_WATER_LEAK_STATUS && data.data?.data_WATER_LEAK_STATUS[0][1] === 'LEAK')) {
                    barge[node][cabins][index].leak = true
                  } else {
                    barge[node][cabins][index].leak = false
                  }

                }
              })
            }
          }


          if (Fulldata?.length > 0) {
            const filterData = Fulldata.filter((obj: any) => obj.id.id === devices.id.id)
            if (filterData[0]) {

              if ((barge[node][cabins][index]?.active || barge[node][cabins][index]?.active === false)) {

                barge[node][cabins] = JSON.parse(JSON.stringify(barge[node][cabins]))
                barge[node][cabins][index] = JSON.parse(JSON.stringify(barge[node][cabins][index]))
                barge[node][cabins][index].active = filterData[0].active
              }
            }
          }

        })
      })

    })
    setRelationData(barge)

  }

  const WebsocketCall = async () => {
    let array: any = []
    let relationTable: any = []
    bargeData.map((node: any, index: number) => {
      relationTable.push({ entityId: node.id.id, cmdId: index + 1 })
      array.push({ entityType: "DEVICE", entityId: node.id.id, scope: "LATEST_TELEMETRY", cmdId: index + 1 })
    })
    dispatch(setRelatonalTable(relationTable))
    const wssUrl = `wss://things.kutana.net/api/ws/plugins/telemetry?token=${TBClient.token}`;
    var webSocket = new WebSocket(wssUrl);

    webSocket.onopen = function () {
      var object = {
        tsSubCmds: array,
        historyCmds: [],
        attrSubCmds: []
      };
      webSocket.send(JSON.stringify(object));
    }
    webSocket.onmessage = function (event) {
      if (networkError) {
        dispatch(setNetworkError(false));
      }
      let array: any = []
      var received_msg = event.data;
      if (JSON.parse(received_msg).data && relationtable.length > 0) {
        array.push(JSON.parse(received_msg))
      }
      setWebsocketArray(array)
    };
    webSocket.onclose = function () {
      console.log('WebSocket connection closed');
      dispatch(setNetworkError(true));

      setTimeout(() => {
        console.log('Attempting to reconnect...');
        WebsocketCall();
      }, 5000);
    };
  }
  useEffect(() => {
    if (websocketArray.length > 0) {
      WebsocketData([])
    }
  }, [websocketArray])
  useEffect(() => {
    if (websocketArray.length > 0) {
      dispatch(setData(relationData));
    }
  }, [relationData])












  return (
    <div className="flex  justify-between p-1 w-full h-16 bg-toolbar-color items-center">
      {/* <div className="text-secondary-text ml-5 text-xl font-bold"><img className="h-10" src={VoipHawkLogo} alt="logo" /></div> */}

      <div className="text-secondary-text pl-5 text-xl font-bold ">
        <img src={Logo} alt="logo" className="h-[30px] w-[187px]  cursor-pointer bg-toolbar-color" onClick={() => navigate("/app", { replace: true })} />
      </div>
      <div className="relative ml-3 flex">
        <div className="flex justify-center items-center">
          {/* <img src={Notification} alt="Notification" /> */}
        </div>
        <Menu as="div" className="relative text-left flex flex-row ">
          <div className="">
            <Menu.Button className="inline-flex w-full capitalize justify-center gap-x-1.5 rounded-md  px-3 py-2 ttext-gray-900 hover:bg-gray-50">
              <div className="bg-[#FFFFFF] rounded-[47px] border border-[#FF8500] p-1">
                <button
                  type="button"
                  className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://cdn.iconscout.com/icon/free/png-512/free-avatar-380-456332.png?f=webp&w=256"
                    alt=""
                  />
                  <span className="px-2 hidden flex items-center text-lg text-[#313638] font-bold"></span>
                </button>
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute   right-0 z-[10000] mt-[57px] w-56 origin-top-right rounded-[3px] bg-[white] shadow-lg  focus:outline-none">
              <div className="py-1">
                <hr />
                <Menu.Item>
                  <div
                    onClick={() => handleLogOut()}
                    className={classNames(
                      "text-gray-700 cursor-pointer block px-4 py-2 text-sm hover:bg-hover"
                    )}
                  >
                    {t("SignOut")} <img src={logout} alt="" className="h-5 w-5 float-right"></img>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
export default ToolBar;
