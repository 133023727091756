/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react";
import FadeIn from "react-fade-in";
import ComponentMap from "../../../atom/componentmap";
import { addAccount } from "../../../services/api";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import regEx from "../../../config/regex";
import Modal from "../../../atom/modal";
import Notification from "../../../atom/Notification";
import Welcome from "../../../utils/images/welcome.png";
import * as LottieSuccess from '../../../utils/lottie/success.json';
import { getENVData } from "../../../config/environment";
import Lottiefy from "../../../atom/lottie/lottie";

const SignUp = () => {
  const [signuploader, setSignuploader] = useState(false);
  const [error, setError] = useState('');
  const [signUpData, setSignUpData] = useState<any>({});
  const environment = getENVData();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [data, setData] = useState<any>([
    {
      title: "Company Name",
      name: "name",
      type: "textbox",
      co_code: "",
      value: "",
      required: true,
      errormessage: "Please enter Company Name.",
    },
    {
      title: "First Name",
      name: "first_name",
      type: "textbox",
      layout: 3,
      co_code: "",
      value: "",
      required: true,
      errormessage: "Please enter First Name.",
    },
    {
      title: "Last Name",
      name: "last_name",
      type: "textbox",
      layout: 3,
      co_code: "",
      value: "",
      required: true,
      errormessage: "Please enter Last Name.",
    },
    {
      title: "Email",
      name: "email",
      type: "email",
      regEx: regEx.email,
      co_code: "",
      value: "",
      required: true,
      errormessage: "Please enter a valid Email",
    },
    {
      title: "Contact Number",
      name: "contact_number",
      type: "number",
      regEx: regEx.number,
      maxLength: 14,
      co_code: "",
      value: "",
      required: true,
      errormessage: "Please enter a valid Contact Number.",
    },
  ])

  const submitSignUp = (payload: any) => {
    payload.display_name = payload.name
    payload.time_zone = timeZone
    setSignuploader(true)
    payload.name = payload.name.toLowerCase().replace(/[^a-zA-Z ]/g, '').replaceAll(' ', '')
    addAccount(payload).then((res) => {
      if (res.uuid) {
        setSignUpData(payload)
      } else {
        setSignuploader(false)
        let upd = [...data]
        if (res.data.parameter === 'user') {
          // setError('Email already exist')
          Notification("Already Exist", "Email already exist", "danger")

          upd[3].error = true;
        } else if (res.data.parameter === 'name') {
          // setError('Company Name already exist')
          Notification("Already Exist", "Company Name already exist", "danger")
          upd[0].error = true;
        }
        setData(upd)
      }
    }).catch((error: any) => {
      setSignuploader(false)
    })
  }
  const navigate = () => {
    window.location.href = `https://${signUpData.name}.${environment.domain}`
  }
  const handleError = () => {
    setError('')
  }

  return (
    <div >
      <FadeIn>
        <div className="my-4 text-xl tracking-tight leading-tight">
          Sign Up
        </div>
        <div className="m-[-4px]">
          <ComponentMap data={data} reset={handleError} setData={setData} />
        </div>
        <div className="mt-[4px] ml-[-8px] p-2">
          <ThemedButton
            children={"Sign Up"}
            classname=" w-full mt-10  rounded-[5px]"
            icon={"custom"}
            loading={signuploader}
            valiData={data}
            change={setData}
            theme="primary"
            click={submitSignUp}
          />
        </div>
        {error &&
          <div className="text-error-alpha-40 text-sm capitalize p-2 text-center pt-0">
            {error}
          </div>}
      </FadeIn>
      {signUpData.name &&
        <Modal >
          <div className="p-8 relative w-[600px] h-[320px] -m-[15px] rounded-sm text-primary">
            <div className="absolute -m-8 z-0">
              <Lottiefy loop={false} json={LottieSuccess} width={600} height={320}/>
            </div>
            <div className="absolute w-full h-[320px] -m-8 z-10">
              <div className="p-8 pt-14 flex justify-center">
                <img src={Welcome} className="h-20 " />
              </div>
              <div className="text-center z-10">
                <div>Sign up completed successfully</div>
                <div onClick={() => navigate()} className="  inline-block px-8 py-2 m-8 cursor-pointer hover:bg-primary-alpha-60 rounded-md bg-primary text-primary-text">
                  Let's go
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}
export default SignUp