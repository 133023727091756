import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import LayoutConfig1 from "./layout1.config";
import { useApp } from "../../appContext";
import LeftSideBar from "./components/leftSideBar/leftSideBar";
import ToolBar from "./components/toolbar/toolbar";
import { useTheme } from "../../theme/themeContext";
// import CookiePolicy from "./components/cookies";
import Loader from "../../containers/loader";
import BrudCrubs from "./components/brudcrubs/brudcrubs";
import { useSelector } from "react-redux";
const Layout1 = () => {
    // const { i18n } = useTranslation();
    // const [currentLanguage, setCurrentLanguage] = useState("en");
    const [grantPermission, setGrantPermission] = useState(0);
    const { theme } = useTheme();
    const appContext = useApp();
    const { routes } = appContext;
    const navigate = useNavigate();
    const navigation = useLocation();
    const brudCrubName = useSelector((state:any) => state.breadCrumbs);

    
    // const { loggedUserRoles } = useSelector((state: any) => state.Auth);
    const currentPathConfiguration = routes.find(route => route.path === '/' + navigation.pathname.split('/')[2]);
    // const layoutConfiguration = currentPathConfiguration && currentPathConfiguration.config ? currentPathConfiguration.config : LayoutConfig1
    useEffect(() => {
        // i18n.changeLanguage("en");
        const cPolicy = window.localStorage.getItem("cPolicy");
        if (cPolicy && cPolicy === "true") {
        }
        setGrantPermission(1)
    }, [])

    // useEffect(() => {

    // let parent = currentPathConfiguration?.path.split('/')[1]
    // let Board = DashboardItems.findIndex((node: any) => {
    //     return node.item === parent
    // })
    // if (loggedUserRoles === false) {
    //     console.log("loggedUserRoles.length", loggedUserRoles)
    //     setGrantPermission(-2)
    // }
    // else if (loggedUserRoles.length) {
    //     findNavigations()
    // }
    // if (Board >= 0) {

    //     setTimeout(() => {
    //         componentTheme(DashboardItems[Board].primary, () => {

    //         })

    //     }, 200);
    // }
    // }, [currentPathConfiguration,
    //  loggedUserRoles
    // ])

    // function findNavigations() {
    // let result: any = [];
    // if (loggedUserRoles.length) {
    //     loggedUserRoles.forEach((role: any) => {
    //         DashboardItems.forEach((node: any) => {
    //             if (node?.permissions?.includes(role.name)) {
    //                 result.push(node)
    //             }
    //         })
    //     })
    // }
    // let parent = currentPathConfiguration?.path.split('/')[1]
    // let perimit = result.find((node: any) => {
    //     return node.item === parent
    // })
    // if (perimit?.item || navigation.pathname === '/app/home' || navigation.pathname === '/app' || navigation.pathname === '/app/') {
    //     setGrantPermission(1)
    // } else if (loggedUserRoles.length !== 0) {
    //     setGrantPermission(-1)
    // }
    // console.log("findNavigations", perimit, navigation.pathname)

    // return result
    // }
    const navigateTo = () => {
        navigate('/app/home', { replace: true });
    }
    return (
        <>
            {/* <CookiePolicy /> */}
            <div className={`${theme === 'dark' ? 'dark' : ''} w-full flex flex-col items-stretch min-h-screen  `}>
                {/* {layoutConfiguration.defaults.toolbar.display && } */}
                <ToolBar />
                {grantPermission === 1 || true ?
                    <div>
                        {/* <div>
                            <BrudCrubs endNode={brudCrubName} />
                        </div> */}
                        <div className="w-full h-[calc(100vh-60px)] flex">
                            <div className="bg-[#32394f] "><LeftSideBar currentPathConfiguration={currentPathConfiguration} /></div>
                            <div className="relative w-full h-full bg-white overflow-scroll ">
                                <div className="w-full h-full mx-auto ">
                                    <div className={` relative z-10 bg-[#4E515C] h-full w-full `}>
                                    <BrudCrubs endNode={brudCrubName} />
                                        <main className="mx-auto w-full h-full bg-[#4E515C] dark:bg-ternary text-secondary-text line-grid b">
                                            <Outlet />
                                         </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : grantPermission === -1 ? <div className=" h-[calc(100vh-60px)] w-full bg-gray-100 flex items-center">
                        <div className="w-full flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                            <div className="max-w-md">
                                <div className="text-5xl font-dark font-bold">404</div>
                                <p className="text-2xl md:text-3xl font-light leading-normal">
                                    Sorry, we couldn't find this page. </p>
                                <p className="mb-8">But dont worry, you can find plenty of other things on our homepage.</p>
                                <button onClick={() => navigateTo()} className="px-4 bg-primary hover:bg-primary-alpha-80 text-primary-text inline py-2 text-sm font-medium rounded-md ">Back to homepage</button>
                            </div>
                            <div className="max-w-lg">
                            </div>
                        </div>
                    </div>
                        : grantPermission === -2 ? <div className=" h-[calc(100vh-60px)] w-full bg-gray-100 flex items-center">
                            <div className="w-full flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                                <div className="max-w-md">
                                    <div className="text-5xl font-dark font-bold">401</div>
                                    <p
                                        className="text-2xl md:text-3xl font-light leading-normal"
                                    >Sorry, you have no permission to access this page. </p>
                                    <p className="mb-8">Please contact your administrator for more.</p>

                                </div>
                                <div className="max-w-lg">
                                </div>

                            </div>
                        </div>
                            : <div className=" w-[100%] p-4 h-[calc(100vh-112px)] flex justify-center items-center text-center content-center">
                                <Loader />
                            </div>}
            </div>
        </>
    )
}
export default Layout1;