import React, { useState } from "react";
import "./table.css";
import moment from "moment";
import waterDrop from '../assets/waterDroplet.svg'
import CountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { setRowData } from "../toolkit/reducers/thingsBoardSlice";

const Table = (props: any) => {
  const {
    tableData,
    tableHeader,
    setSelectedRow,
    timeZonePicker,
    handleFilteredPageNumberNav,
    pageNumber,
    totalResults,
    pageLength,
  } = props;
  const [checkedData, setCheckedData] = useState<any>([]);

  const dispatch = useDispatch();
  const SetRedux = async (node: any) => {
    dispatch(setRowData(node));
    setSelectedRow(node);
  };



  console.log("tableDatatableDatatableData", tableData);
  const handleUtc = (dateValue: any) => {
    let utcTime = moment.utc(dateValue);
    let Local = utcTime.format("YYYY-MM-DD HH:mm:ss");
    if (timeZonePicker[0]) {
      Local = timeZonePicker[0].value;
    }
    let LocalTime = moment(utcTime, Local).format("YYYY-MM-DD HH:mm:ss");
    return LocalTime;
  };

  const Cflag = (countryCode: any) => {
    return <CountryFlag countryCode={countryCode} svg />;
  };

  return (
    <div>
      <div className="w-full font-light text-sm p-[25px]">
        <table className="border-1 border-gray-400 ">
          <tr>
            {tableHeader?.map((header: any, index: number) => {
              return (
                <th
                  key={index}
                  className=" text-[white] bg-[#1E2742]  border-[3px] border-[#4E515C] h-[42px] w-[198px] "
                >
                  <div className="flex justify-start  ">
                    {header.displayName}
                  </div>
                </th>
              );
            })}
          </tr>
          {tableData?.reason !== "bad request" &&
            tableData
              ?.filter((node: any) => {
                if (checkedData?.length > 0) {
                  const res = checkedData.filter((checked: any) => {
                    return checked.name === node[checked.type];
                  });
                  if (res?.length) {
                    return true;
                  }
                  return false;
                } else {
                  return true;
                }
              })
              ?.map((node: any, index: number) => {

                return (
                  <tr
                    className="hover:bg-primary-alpha-10 bg-[#13192F] even:bg-[#181F35] text-[white] h-[70px] w-[198px]"
                    onClick={() => SetRedux(node)}
                  >
                    {tableHeader.map((header: any) => {

                      return (
                        <td className="border-[3px] border-[#4E515C] hover:outline-[1px] hover:outline hover:outline-primary-alpha-60">
                          {header?.type === "bubble" ? (
                            <span className="text-[14px] text-[#A8A8A8] rounded-[50px] py-[3px] px-[10px] bg-[#F5F5F5] h-[18px]">
                              {node[header.name]}
                            </span>
                          ) : header?.type === "button" ? (
                            <>
                              {header.items.map((item: any) => {
                                return (
                                  <span className="px-4 text-center ">
                                    <span onClick={item.action}>
                                      {item.icon} {item.icontext}
                                    </span>
                                  </span>
                                );
                              })}
                            </>
                          ) : header?.type === "sersor" ? (
                            <>
                              {node["active"] ? (
                                <h4 className="flex justify-between text-[white] font-semibold text-sm">
                                  {node[header?.name]} {node["leak"] ? <img src={waterDrop} alt="" className=" h-[20px] w-[20px]" /> : null}
                                </h4>
                              ) : (
                                <h4 className="flex justify-between text-error font-semibold text-sm">
                                  {node[header?.name]} {node["leak"] ? <img src={waterDrop} alt="" className=" h-[20px] w-[20px]" /> : null}
                                </h4>
                              )}
                            </>
                          ) : header?.type === "array" ? (
                            <>
                              {node[header?.name]?.map(
                                (item: any, index: number) => {
                                  return (
                                    <span>
                                      {index !== 0 ? ", " : null}
                                      {item.name}
                                    </span>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div
                              title={node[header.name] || ""}
                              className={`truncate normal-case text-main-white font-bold
                              ${header.name === "id"
                                  ? "w-[50px] h-[20px]"
                                  : "max-w-[200px] h-[20px]"
                                }
                            `}
                            >
                              {node[header.name] === "" ||
                                node[header.name] === undefined ||
                                node[header.name] === null ||
                                node[header.name] === 0 ? (
                                <span className="text-[grey]">-</span>
                              ) : header.name === "calldate" ||
                                header.name === "createdTime" ? (
                                handleUtc(node[header.name])
                              ) : header.name === "caller_domain" ? (
                                <div>
                                  {Cflag(node["sipcallerip_country_code"])}
                                  <span className="ml-1 ">
                                    {node[header.name]}
                                  </span>
                                </div>
                              ) : header.name === "called_domain" ? (
                                <div>
                                  {Cflag(node["sipcalledip_country_code"])}
                                  <span className="ml-1">
                                    {node[header.name]}
                                  </span>
                                </div>
                              ) : (
                                node[header.name]
                              )}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </table>
        <div className="text-[#888] mt-4">
          <nav className="flex">
            {Math.ceil(totalResults / pageLength) > 1 && (
              <ul className="inline-flex -space-x-px text-sm pr-6">
                <li>
                  <a
                    onClick={() => handleFilteredPageNumberNav(1)}
                    className="flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-sm hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {/* <span className="sr-only">Previous</span> */}
                    <svg
                      className="w-2.5 h-2.5 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 1 1 5l4 4"
                      />
                    </svg>
                  </a>
                </li>
                {[...Array(Math.ceil(totalResults / pageLength))].map(
                  (_, i) => {
                    if (
                      (pageNumber - 4 <= i && pageNumber + 2 >= i) ||
                      (pageNumber < 4 && i < 7) ||
                      (totalResults / pageLength - pageNumber < 3 &&
                        i > totalResults / pageLength - 7)
                    )
                      return (
                        <li>
                          <a
                            onClick={() => handleFilteredPageNumberNav(i + 1)}
                            className={`${pageNumber === i + 1
                              ? "bg-primary-alpha-80 text-primary-text"
                              : ""
                              } flex  cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-[gray] hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                          >
                            {i + 1}
                          </a>
                        </li>
                      );
                  }
                )}
                <li>
                  <a
                    onClick={() =>
                      handleFilteredPageNumberNav(
                        Math.ceil(totalResults / pageLength)
                      )
                    }
                    className="flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-sm hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <svg
                      className="w-2 h-2.5 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            )}
            <div className="p-[6px]  justify-center items-center text-main-white">
              Showing{" "}
              <b>
                {(pageNumber - 1) * pageLength + 1}-
                {pageNumber * pageLength < totalResults
                  ? pageNumber * pageLength
                  : totalResults}
              </b>{" "}
              of <b>{totalResults}</b> Results
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Table;
