// src/features/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthRepoState {
  loggedUser: {};
  loggedUserRoles: any;
  accountActiveStep: number;
}

const initialState: AuthRepoState = {
  loggedUser: {},
  loggedUserRoles: [],
  accountActiveStep: 1,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{}>) => {
      state.loggedUser = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<any>) => {
      state.loggedUserRoles = action.payload;
    },
    setAccountActiveState: (state, action: PayloadAction<number>) => {
      state.accountActiveStep = action.payload;
    },
  },
});

export const { setUser, setUserRoles, setAccountActiveState } = authSlice.actions;
export default authSlice.reducer;
