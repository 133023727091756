import axios from "axios";

let TBClient: any

const setTBClient = (tokens: any) => {
    axios.defaults.headers.post["X-Authorization"] = 'Bearer ' + tokens.token;
    axios.defaults.headers.put["X-Authorization"] = 'Bearer ' + tokens.token;
    axios.defaults.headers.delete["X-Authorization"] = 'Bearer ' + tokens.token;
    axios.defaults.headers.get["X-Authorization"] = 'Bearer ' + tokens.token;
    TBClient = tokens
}
export {
    TBClient,
    setTBClient
}