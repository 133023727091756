import { Link, useNavigate } from "react-router-dom";
import { useApp } from "../../../../appContext";
import { useLocation } from "react-router-dom";
import FadeIn from "react-fade-in";
import { useTranslation } from "react-i18next";
import { LeftMenuItems } from "../../../../config/leftMenuItems";
import EmbededItems from "../../../../assets/embeded_items.json";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import Cabin from '../../../../assets/cabin.svg' 
import CabinWhite from '../../../../assets/cabin-white.svg' 
import Barge from '../../../../assets/barge.svg'
import BargeWhite from '../../../../assets/barge-white.svg'
import Devices from '../../../../assets/devices.svg'
import DevicesWhite from '../../../../assets/devices-white.svg'
import {
  setEmbeddedItems,
  setMenu,
  setSubmenu,
} from "../../../../toolkit/reducers/embeddedItemsSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import axios from "axios";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { AnimatePresence, motion } from "framer-motion";

const LeftSideBar = (props: any) => {

  const navigation = useLocation();
  const showActiveMenu:Function = ()=>{
    let pathArray = navigation.pathname.split("/");
    // console.log("pathARRay in left",pathArray)
    return (pathArray[pathArray.length-1]+""==="barge") ? "Cdr-Fixed-Barge" :
    (pathArray[pathArray.length-1]+"" ==="cabins" ? "Cdr-Fixed-Cabins" : (pathArray[pathArray.length-1]+"" === "devices"? "Cdr-Fixed-Menu":"") )
  }

  const { currentPathConfiguration } = props;
  const [hideMenu, setHideMenu] = useState(true);
  const [activeMainMenu, setActiveMainMenu] = useState<string>(showActiveMenu());
  const [activePage, setActivePage] = useState<string>(showActiveMenu());
  // const [submenu, setSubmenu] = useState<any>([]);
  // const [menu, setMenu] = useState<any>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const [submenuEmpty, setSubmenuEmpty] = useState(true);
  const [activeDeepMenu, setActiveDeepMenu] = useState<any>("");

  // const item: any = currentPathConfiguration.path.replace('/', '');
  let SubItem: any = navigation.pathname.split("/")[3] || "";
  SubItem = SubItem?.includes("_") ? SubItem?.replace(/_/g, "") : SubItem; //To enable selected highlight for items with "_" in url
  // console.log("Subitem=>", SubItem);

  // const navigateTo = (nav: string) => {
  //   navigate(`app/${nav}`, { replace: true });
  // };
  // const handleMenu = (node: any, payload: any) => {

  // };

  const EmbeddedItems = useSelector((state: any) => state.embeddedItems);
  const menu = EmbeddedItems.menu;
  const submenu = EmbeddedItems.submenu;
  const [staticMenu, setStaticMenu] = useState<any>([]);

  const [staticSubMenu, setStaticSubmenu] = useState<any>([]);

  


  return (
    <div
      className={
        " lg:w-[290px] h-[calc(100vh-80px)] bg-[#32394f] border-r-primary-alpha-30 border-r-[1px] flex flex-col justify-between  mt-[0px]  "
      }
      id="SideBarProductOwnerRoot"
    >
      <div className="w-full flex flex-col justify-between h-full">
        <FadeIn className="flex flex-col relative h-full overflow-y-auto font-medium text-lg font-lato pt-2 ">
          <div className="relative cursor-pointer items-center  rounded-[4px] max-w-[254px] m-auto my-3">
            <Link
              to={"app/barge"}
              // className="text-sm flex flex-row gap-2"
              className={
                activeMainMenu === "Cdr-Fixed-Barge" ||
                  activePage === "Cdr-Fixed-Barge"
                  ? "w-full  cursor-pointer flex flex-row justify-start items-center gap-3 rounded-[4px] bg-[#FFFAFA] border border-[#FF5F00] px-3 py-4 text-[#1E2742] "
                  : "w-full  hover:bg-[#1E2742] cursor-pointer flex flex-row justify-start rounded-[4px] items-center gap-3 border border-[#32394f] transition-colors delay-75 px-3 py-4  text-main-white "
              }
              onClick={() => {
                setActiveMainMenu("Cdr-Fixed-Barge");
                setActivePage("Cdr-Fixed-Barge");
              }}
            >
              {/* <MenuIcon /> */}
              {activeMainMenu === "Cdr-Fixed-Barge" || activePage === "Cdr-Fixed-Barge" ? <img  src={Barge} alt="Barge" /> : <img  src={BargeWhite} alt="Barge" /> }
              <span className="hidden lg:inline sm:me-2  ">Barge</span>
            </Link>
          </div>
          <div className="relative cursor-pointer items-center rounded-[4px] max-w-[254px] m-auto my-3 ">
            <Link
              to={"app/cabins"}
              // className="text-sm flex flex-row gap-2"
              className={
                activeMainMenu === "Cdr-Fixed-Cabins" ||
                  activePage === "Cdr-Fixed-Cabins"
                  ? " w-full cursor-pointer flex flex-row justify-start items-center gap-3 rounded-[4px] bg-[#FFFAFA] border border-[#FF5F00] px-3 py-4  text-[#1E2742] "
                  : "w-full hover:bg-[#1E2742] cursor-pinter flex flex-row justify-start rounded-[5px] items-center gap-3 border border-[#32394f] transition-colors delay-75 px-3 py-4 text-main-white "
              }
              onClick={() => {
                setActiveMainMenu("Cdr-Fixed-Cabins");
                setActivePage("Cdr-Fixed-Cabins");
              }}
            >
              {/* <MenuIcon /> */}
             {activeMainMenu === "Cdr-Fixed-Cabins" || activePage === "Cdr-Fixed-Cabins" ? <img  src={Cabin} alt="Cabin" /> : <img  src={CabinWhite} alt="Cabin" /> }
             <span className="hidden lg:inline sm:me-2">Cabins</span>
            </Link>
          </div>
          <div className="relative cursor-pointer items-center rounded-[4px] max-w-[254px] m-auto my-3 ">
            <Link
              to={"app/devices"}
              // className="text-sm flex flex-row gap-2"
              className={
                activeMainMenu === "Cdr-Fixed-Menu" ||
                  activePage === "Cdr-Fixed-Menu"
                  ? " w-full cursor-pointer flex flex-row justify-start items-center gap-3 rounded-[4px] bg-[#FFFAFA] border border-[#FF5F00] px-3 py-4 text-[#1E2742] "
                  : "w-full hover:bg-[#1E2742]  cursor-pinter flex flex-row justify-start rounded-[4px] items-center gap-3 border border-[#32394f] transition-colors delay-75 px-3 py-4  text-main-white"
              }
              onClick={() => {
                setActiveMainMenu("Cdr-Fixed-Menu");
                setActivePage("Cdr-Fixed-Menu");
              }}
            >
              {/* <MenuIcon /> */}
              {activeMainMenu === "Cdr-Fixed-Menu" || activePage === "Cdr-Fixed-Menu" ? <img  src={Devices} alt="Devices" /> : <img  src={DevicesWhite} alt="Devices" /> }
              <span className="hidden lg:inline sm:me-3">Devices</span>
            </Link>
          </div>






        </FadeIn>
        {/* <Link
          to={"app/settings"}
          className={
            activeMainMenu === "settings-Fixed-Menu" ||
              activePage === "settings-Fixed-Menu"
              ? " bg-primary-alpha-10   w-full text-table-heading-blue border-r-[2.8px] p-4 cursor-pointer text-sm flex flex-row justify-start items-center gap-2 rounded-[2px]"
              : "w-full text-secondary-text hover:text-table-heading-blue p-4 cursor-pointer text-sm flex flex-row justify-start rounded-[5px] items-center gap-2 transition-colors delay-75"
          }
          onClick={() => {
            setActiveMainMenu("settings-Fixed-Menu");
            setActivePage("settings-Fixed-Menu");
          }}
        >
          <div className="left-0 bottom-0 ml-[10px] flex gap-2">
            <SettingsIcon fontSize="small" />
            Settings
          </div>
        </Link> */}
      </div>
    </div>
  );
};
export default LeftSideBar;
