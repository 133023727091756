
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  paddingLeft: "9px",
  border:"1px solid #DADADA",
  borderRadius: "8px",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("lg")]: {
    // marginLeft: theme.spacing(1),
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color:"#2d2d2d",
  padding:"2px",
  right:"0px"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({ 
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

function SearchBar(props) {
  const { searchtext, setSearchtext, placeholder } = props;
  console.log("searchtext", searchtext);
  console.log(" setSearchText", setSearchtext)
  return (
    <div className="relative w-full">
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase  
        placeholder={placeholder}
        value={searchtext}
        onChange={(e) => setSearchtext(e.target.value)}
      />
    </Search>
    </div>
  );
}
export default SearchBar;