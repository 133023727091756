import { useEffect, useState, useRef } from "react"
import { useApp } from "../../../../appContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useDispatch, useSelector } from "react-redux";
import "../../../../App.css"

const BrudCrubs = (props: any) => {
    const appContext = useApp();
    const { t } = useTranslation();
    const { endNode } = props;

    const navigate = useNavigate();
    const navigation = useLocation();
    const [menuItems, setMenuItems] = useState<any>([])
    const [activeIndex, setActiveIndex] = useState<number>(-1)
    const [brudcrubs, setBrudcrubs] = useState<any>([{ display: "Mayship", path: 'Mayship' }]);
    const { routes } = appContext;

    const travelTo = (brud: any, index: any) => {
        let sliceString: any

        if (brudcrubs[index].display && (brudcrubs[index].display === 'view' || brudcrubs[index - 1].display === 'view')) {
            let indexss = brudcrubs[index].display === 'view' ? index - 1 : index - 2
            sliceString = navigation.pathname.split('/').slice(1, indexss + 2).join("/")



        } else {
            sliceString = navigation.pathname.split('/').slice(1, index + 2).join("/")
        }



        navigate(sliceString)
    }

    // const { loggedUserRoles } = useSelector((state: any) => state.Auth);
    const dispatch = useDispatch();
    const navigateTo = (nav: any, index: number) => {
        let path = nav;
        if (nav?.basePath) {
            path = nav?.basePath
        } else if (nav?.path) {
            path = nav?.path
        } else if (nav?.item) {
            path = nav?.item
        }
        let result: any = [];
        if (index === 0) {
            navigate(`/app/${path}`, { replace: true });
            setMenuItems([])
            setActiveIndex(-1)
        }
        // else if (index === 1) {
        //     if (loggedUserRoles.length) {
        //         loggedUserRoles.forEach((role: any) => {
        //             // DashboardItems.forEach((node: any) => {
        //             //     // if (node?.permissions?.includes(role.name)) {
        //             //     //     result.push(node)
        //             //     // }
        //             // })
        //         })
        //     }
        //     setMenuItems(result)
        //     setActiveIndex(index)
        // } 
        else if (index === 2)
        // &&
        //  LeftMenuItems[brudcrubs[1].path as "control" || 'system' || 'fax' || "settings"])
        {
            // setMenuItems(LeftMenuItems[brudcrubs[1].path as "control" || 'system' || 'fax' || "settings"])
            setActiveIndex(index)
        }
    };
    function contains(target: any, pattern: any) {
        let value = 0;
        pattern.forEach(function (word: any) {
            value = value + target.includes(word);
        });
        return (value === 1)
    }
    useEffect(() => {
        let parent: any = navigation.pathname.split('/')
        parent = parent.filter((path: any) => path !== '')
        let path = [{ display: "Mayship", path: '' }]
        let key: string = '';
        parent.map((node: any, index: number) => {

            key = key + '/' + node
            key = key.replace('/app', '')
            const item = routes.find(route => route.path === key);
            if (item) {
                path.push({ display: item.title, path: item.path.slice(1) })
            } else if (!contains(node, ['-', 'new', 'edit', 'app', 'id', 'tab', 'add'])) {

                node = node.includes("_") ? node.replace(/_/g, " ") : node
                node = node.includes("%20") ? node.replace(/%20/g, " ") : node
                path.push({ display: node, path: '' })
                // } else if (!contains(node, ['-', 'new', 'edit', 'app', 'id', 'tab', 'add'])) {
            } else if (index <= 2 && !contains(node, ['-', 'new', 'edit', 'app', 'id', 'tab', 'add'])) {
                path.push({ display: node, path: '' })
            }
        })

        setBrudcrubs([...path])
    }, [navigation.pathname])

    const wrapperRef: any = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {

                setMenuItems([])
                setActiveIndex(-1)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    return <div ref={wrapperRef} className="bg-[#4E515C] pl-[10px]">
        <ol className=" flex  items-center w-full mb-[1px] -space-x-[11px] text-xs md:text-base font-lato font-medium text-center text-gray-500 bg-[#4E515C] ">
            {brudcrubs.map((brudcrub: any, index: number) => {

                return <li key={index} className={`flex relative  ml-0 mr-0 p-2 capitalize items-center  text-[#fff] font-normal `}
                    // onClick={() => navigateTo(brudcrub.path, index)}
                    onClick={() => brudcrubs.length - 1 !== index && travelTo(brudcrub.display, index)}
                >
                    <label className={`${brudcrubs.length - 1 === index ? "font-bold" : ""} ${brudcrubs.length - 1 !== index ? "cursor-pointer" : "cursor-default"}`}>{brudcrub.display}</label>
                    {(brudcrubs.length - 1 !== index || (endNode?.brudCrubName.length !== 0 && endNode !== undefined)) &&
                        <svg aria-hidden="true" className=" w-4 h-4 ms-2 me-[4px]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
                    }
                    {/* {
                        index === activeIndex && <div className="absolute h-5 w-[200px]  text-primary border border-primary-alpha-20 bg-ternary text-left p-0 -ml-6 top-[28px] z-10">
                            <FadeIn>
                                {menuItems.map((item: any, index: number) => {
                                    return <div key={item.item} onClick={() => navigateTo(item, 0)} className="px-4 py-2 cursor-pointer hover:bg-primary-alpha-20">
                                        {t(item.displayName)}
                                    </div>
                                })}
                            </FadeIn>
                        </div>
                    } */}
                </li>
            })}
            {endNode ? Array.isArray(endNode.brudCrubName) ?
                endNode.brudCrubName.map((node: string, idx: number) => {
                    return <>
                        <li className={`flex relative ml-0 mr-0 px-3 border-[#C6C6C6] border rounded-[20px] bg-[#ffffff] capitalize items-center text-[10px] text-[#C6C6C6] font-normal cursor-pointer`}>
                            {t(node)}
                        </li>
                        {/* {(endNode.length - 1 !== idx) &&
                            <svg aria-hidden="true" className=" w-4 h-4 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
                        } */}
                    </>
                }) :
                endNode !== '' &&

                <li className={`flex capitalize items-center text-ternary-text`}>
                    {t(endNode)}
                </li>
                : ''}
        </ol>

    </div>

}
export default BrudCrubs;