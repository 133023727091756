// counterSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addChildrensToMapData } from "../../services/utilities";

interface FilterItemsState {
  FilterItem: any[];
  //   menu:any[],
  //   submenu:any
}

const initialState: FilterItemsState = {
  FilterItem: [
    {
      type: "label",
      value: "Caller Name", //0
      layout: 2,
      disabled: true,
      hidden: true,
    },
    {
      name: "callerNameDropdown",
      label: "Position",
      type: "dropdown", //1
      value: "",
      default: "",
      layout: 3,
      childrens: ["Start", "End", "Contain"],
    },
    {
      type: "textbox",
      name: "callername", //2
      value: "",
      default: "",
      label: "Caller Name",
      title: "Caller Name",
      layout: 3,
    },
    {
      name: "cnumber",
      type: "dropdown",
      value: "", //3
      childrens: ["Caller Or Called", "Caller"],
      layout: 1,
      label: "Number ",
    },
    {
      name: "callerNumberDropdown",
      type: "dropdown", //4
      value: "",
      default: "",
      layout: 1,
      label: "Position",
      childrens: ["Start", "End", "Contain"],
    },
    {
      type: "textbox",
      name: "caller",
      layout: 2, //5
      value: "",
      default: "",
      label: "caller",
      title: "Caller Number",
    },
    {
      type: "textbox",
      name: "called",
      layout: 2,
      value: "",
      default: "", //6
      label: "called",
      title: "Called Number",
      disabled: false,
    },

    {
      name: "pnumber",
      type: "dropdown",
      value: "", //7
      childrens: ["Caller Or Called", "Caller"],
      layout: 2,
      label: "Port ",
    },

    {
      type: "number",
      name: "callerport",
      layout: 2, //8
      value: "",
      default: "",
      label: "caller",
      title: "Caller Port",
    },
    {
      type: "number",
      name: "calledport",
      layout: 2,
      value: "",
      default: "", //9
      label: "called",
      title: "Called Port",
      disabled: false,
    },
    {
      name: "ipnumber",
      type: "dropdown",
      value: "", //10
      childrens: ["Caller Or Called", "Caller"],
      layout: 2,
      label: "IP",
    },

    {
      type: "textbox",
      name: "callerip",
      layout: 2, //11
      value: "",
      default: "",
      label: "caller",
      title: "Caller IP",
    },
    {
      type: "textbox",
      name: "calledip",
      layout: 2,
      value: "",
      default: "", //12
      label: "called",
      title: "Called IP",
      disabled: false,
    },

    {
      type: "dropdown",
      name: "whohangup",
      layout: 3,
      value: "",
      childrens: ["Callee", "Caller", "Nil"],
      default: "", //12
      label: "Who hanged up",
      disabled: false,
    },
    {
      type: "number",
      name: "id",
      layout: 3,
      value: "",
      default: "", //13
      label: "id",
      title: "ID",
      disabled: false,
    },
    {
      type: "number",
      name: "callduralower",
      keyParent: "callduration",
      layout: 3,
      value: "",
      default: "", //13
      label: "callDuration",
      title: "Call Duration <",
      disabled: false,
    },
    {
      type: "number",
      name: "callduragreterequal",
      keyParent: "callduration",
      layout: 3,
      value: "",
      default: "", //13
      label: "callDuration",
      title: "Call Duration >=",
      disabled: false,
    },
    {
      type: "number",
      name: "connectduralower",
      layout: 3,
      keyParent: "connectduration",
      value: "",
      default: "", //13
      label: "connectDuration",
      title: "Connect Duration <",
      disabled: false,
    },
    {
      type: "number",
      name: "connectduragreterequal",
      layout: 3,
      keyParent: "connectduration",
      value: "",
      default: "", //13
      label: "connectDuration",
      title: "Connect Duration >=",
      disabled: false,
    },
    {
      type: "textbox",
      name: "call_id",
      layout: 3,
      value: "",
      default: "", //13
      label: "callId",
      title: "Call Id",
      disabled: false,
    },
    {
      type: "dropdown",
      name: "lastsipresp",
      displayProps: { label: "lastsipresponse", value: "lastsipresponse_id" },
      layout: 3,
      value: "",
      childrens: [],
      default: "", //13
      label: "Last Sip Response Id",
      // title: "Last Sip Response Id",
      disabled: false,
    },
  ],
  // menu:[],
  // submenu:[],
};

const filterItems = createSlice({
  name: "FilterItems",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<any[]>) => {
      state.FilterItem = action.payload;
    },
  },
});

export const { setFilter } = filterItems.actions;
export default filterItems.reducer;
