// store.ts
import { configureStore } from '@reduxjs/toolkit';
import counterSlice from './reducers/counterSlice';
import authReducer from './reducers/authSlice'
import numberManagerReducer from './reducers/numberManagerSlice'
import breadCrumbsReducer from './reducers/breadCrumbsSlice';
import embeddedItemsReducer from './reducers/embeddedItemsSlice'
import FilterItemsReducer from './reducers/filterFormSlice'
import thingsBoardSlice from './reducers/thingsBoardSlice';
const store = configureStore({
  reducer: {
    counter: counterSlice,
    auth: authReducer,
    numberManager: numberManagerReducer,
    breadCrumbs: breadCrumbsReducer,
    embeddedItems: embeddedItemsReducer,
    filterItems: FilterItemsReducer,
    thingsBoard: thingsBoardSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
