const paths = {
  us5: `us5-dev.voifinity.in`,
  us3: `us3-test.voifinity.in`,
  // local: `us3-test.voifinity.in`,
  local: `things.kutana.net`,
  // local: `us5-dev.voifinity.in`
};

const us5 = {
  env: "develop",
  baseURL: `https://${paths.us5}/v1/`,
  domain: `${paths.us5}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us5}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us5}/v1/api/download/`,
};
const us3 = {
  env: "testing",
  baseURL: `https://${paths.us3}/v1/`,
  domain: `${paths.us3}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us3}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us3}/v1/api/download/`,
};

const local = {
  env: "localhost",
  baseURL: `https://${paths.local}/api/`,
  domain: `${paths.local}/app`,
  // keyCloak: `https://${paths.us5}/auth/`,
  keyCloak: `https://monitor.mayship.com/auth`,
  webSocket: `wss://${paths.local}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.local}/v1/api/download/`,
};

export function getENVData() {
  let text = window.location.hostname;
  if (text.includes(paths.us5)) {
    return us5;
  } else if (text.includes(paths.us3)) {
    return us3;
  } else {
    return local;
  }
}
