import axios from "axios";
import { remove, put, post, get } from "./api.service";
import { setCabinState, setData, setOriginalData } from "../toolkit/reducers/thingsBoardSlice";
import { TBClient } from "../config/thinksboard";


export async function verifyClient(payload: any) {
  const url = `verifyClient/${payload}`;
  const result = post(url, {});
  return result;
}

export async function addAccount(payload: any) {
  const url = `addTelco`;
  const result = put(url, payload);
  return result;
}
export async function getRoles(payload: any, options: any = {}) {
  // const url = `getRoles`;
  // const result = post(url, payload, options);
  return [];
}
export async function getDeviceTimeseries(payload: { device_id: string, keys: string, limit: number, interval: number, startTs: number, endTs: number }) {
  const url = `plugins/telemetry/DEVICE/${payload.device_id}/values/timeseries?keys=${payload.keys}&limit=${payload.limit}&interval=${payload.interval}&startTs=${payload.startTs}&endTs=${payload.endTs}&useStrictDataTypes=true`;
  const result = get(url);
  return result;
}

export async function getTenantDevices(dispatch: any, token: any) {
  var options: any = {
    method: 'GET',
    url: 'https://things.kutana.net/api/tenant/deviceInfos?pageSize=20&page=0&sortProperty=createdTime&sortOrder=DESC',
    headers: { 'X-Authorization': 'Bearer ' + token },
  };
  await axios(options).then(function (result: any) {

    var data = result.data.data;
    var groupedData: any = [];
    if (data) {
      data.map((node: any, index: number) => {
        const result = node.name.split('-')
        if (result.length === 3) {
          node['barge'] = result[0]
          node['cabin'] = result[1]
          node['sersor'] = result[2];
        } else {
          node['barge'] = index < 6 ? 'Travel Barge 1' : 'Travel Barge 2'
          node['cabin'] = index < 3 ? 'Genaral Cabin 1' : 'Genaral Cabin 2'
          node['sersor'] = 'MileSight Sensor' + index + 1
        }
      })
      groupedData = data.reduce(function (r: any, a: any) {
        r[a.barge] = r[a.barge] || [];
        r[a.barge].push(a);
        return r;
      }, Object.create(null));
      var cbn: any = {}
      Object.keys(groupedData).map((node: any) => {
        let items = groupedData[node].reduce(function (r: any, a: any) {
          r[a.cabin] = r[a.cabin] || [];
          r[a.cabin].push(a);
          return r;
        }, Object.create(null));
        Object.keys(items).map((ObjKey: any) => {
          cbn[node + ObjKey] = items[ObjKey].filter((ii: any) => {
            return ii.active === true
          }).length
        });
        groupedData[node] = items
      })
      console.log("groupedData", cbn);

      dispatch(setCabinState(cbn))
      dispatch(setData(groupedData))
      dispatch(setOriginalData(result.data.data))

    }
  }).catch(function (error: any) {
    console.error(error);
  });
}


export async function getTenantDevice(token: any) {
  var options: any = {
    method: 'GET',
    url: 'https://things.kutana.net/api/tenant/deviceInfos?pageSize=25&page=0&sortProperty=createdTime&sortOrder=DESC',
    headers: { 'X-Authorization': 'Bearer ' + token },
  };
  return axios(options).then(function (result: any) {
    return result.data.data;
  }).catch(function (error: any) {
    console.error(error);
  });
}