/*
* This Function is used for Updating Childrens
*/
export function addChildrensToMapData(mapData: any, items: any, key: string) {
  let result = mapData.find((node: any) => {
    return node.name === key
  });
  if (result) {
    result.childrens = items;
  }
  return mapData;
}
/*
* This Function is used for Updating Childrens
*/
export function appendChildrensToMapData(mapData: any, items: any, key: string) {
  let result = mapData.find((node: any) => node.name === key);
  if (result) {
    result.childrens.push(...items);
  }
  return mapData;
}

export function updateObjectKeyValue(mapData: any, objectKey: string, key: string, value: any) {
  const data = mapData.find((node: any) => {
    return node.name === objectKey
  })
  if (data) {
    data[key] = value;
  }
  return mapData;
}

export function appendLoopDataToMapData(mapData: any, items: any) {
  let result: any = [];
  items.forEach((item: any) => {
    let copy = JSON.parse(JSON.stringify(mapData));
    copy.forEach((node: any) => {
      Object.keys(item).forEach((key) => {
        if (node.name === key) {
          node.value = key === 'id' ? [item[key]] : item[key];
          node.error = false;
          node.disabled = false;
        }
      });
    });
    result.push(copy)
  })
  return result
}
export function CheckLeak(barge: any, bargeData: any) {
  if (bargeData) {
    let count = 0;
    Object.keys(bargeData[barge]).map((cabin: any) => {
      Object.keys(bargeData[barge][cabin]).map((sensor: any, index: number) => {
        if (bargeData[barge][cabin][index].leak) {
          count += 1;
        }
      })
    })
    return count
  }


}
export function CheckLeakCabin(barge: any, cabin: any, bargeData: any) {
  if (bargeData) {
    let count = 0;
    Object.keys(bargeData[barge][cabin]).map((sensor: any, index: number) => {
      if (bargeData[barge][cabin][index].leak) {
        count += 1;
      }
    })
    return count
  }


}
export function CheckActiveCabin(barge: any, cabin: any, bargeData: any) {
  if (bargeData) {
    let count = 0;
    Object.keys(bargeData[barge][cabin]).map((sensor: any, index: number) => {
      if (!bargeData[barge][cabin][index].active) {
        count += 1;
      }
    })
    return count
  }


}
export function actualDataToMapData(mapData: any, item: any) {
  mapData.forEach((node: any) => {
    const exp = node.name ? node?.name.split('.') : '';
    Object.keys(item).forEach((key) => {
      if (node.name === key) {
        node.value = node.name === 'fallthrough' ? [item[key]] : item[key] !== null ? item[key] : '';
        node.error = false;
        node.disabled = false;
      } else if (node.type === 'password') {
        node.showHint = false;
      } else if (exp.length === 2 && exp[0] === key) {
        node.value = item[exp[0]][exp[1]];
      } else if (exp.length === 3 && exp[0] === key) {
        node.value = item[exp[0]][exp[1]][exp[2]];
      }
      if (node?.reference && node?.reference?.items) {
        node?.reference?.items.forEach((ref: any) => {
          let refData = mapData.find((refMap: any) => {
            return refMap.name === ref
          })
          if (refData) {
            if (node.reference.condition.operator === "=") {
              refData[node.reference.action] = node.value
            } else if (node.reference.condition.operator === "!=") {
              refData[node.reference.action] = !node.value
            }
          }
        })
      }
    });
  });

  mapData.forEach((node: any) => {
    if (node.reference) {
      let refData: any = mapData.find((n: any) => {
        return n.name === node?.reference.name;
      });
      if (refData) {

        if (node.reference.condition.operator === "=") {
          refData[node.reference.action] = node.value;
        }
      }
    }
  });

  return mapData;
}
export function addAKeyValueInListItems(mapData: any, key: string, value: any) {
  mapData.forEach((node: any) => {
    node[key] = node[value] ? node[value] : value;
  })
  return mapData;
}
export function addAKeyValueFromRefListItems(mapData: any, key: string, ref: any) {
  mapData.forEach((node: any) => {
    node[key] = node[ref[0]] + " " + (ref[1] ? node[ref[1]] : '');
  })
  return mapData;
}

export function secondsToHms(d: any) {
  d = Number(d);
  const h = Math.floor(d / 3600).toString().padStart(2, '0'),
    m = Math.floor(d % 3600 / 60).toString().padStart(2, '0'),
    s = Math.floor(d % 60).toString().padStart(2, '0');
  return h + ':' + m + ':' + s;
}
export function randomNumber() {
  const crypto = window.crypto;
  let array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}