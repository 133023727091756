import { useDispatch, useSelector } from "react-redux";
import SensorDetails from "./sensor";
import Loader from "../../loader";
import { useParams } from "react-router-dom";

const CabinSensors = () => {
    const bargeData = useSelector((state: any) => state.thingsBoard.data);
    let { barge, name, cabins } = useParams<any>();
    // console.log("bargeData[barge][cabins]", bargeData, barge, cabins, typeof (barge), typeof (cabins), barge && barge !== undefined && barge !== "undefined" && cabins && cabins !== "undefined" && Object.keys(bargeData).length > 0 ? bargeData[barge] : true);

    let rowData: any = barge && barge !== undefined && barge !== "undefined" && cabins && cabins !== "undefined" && Object.keys(bargeData).length > 0 ? bargeData[barge][cabins] : []

    let length = rowData?.length
    return <div className="  ">
        {Object.keys(rowData)?.length > 0 || rowData?.length > 0 ?
            rowData?.map((node: any) => {
                return <div >
                    <SensorDetails sensorId={node.id.id} sensorData={node} viewType="cabin" length={length} />
                </div>
            }) : <Loader />}
    </div>
}
export default CabinSensors