import { useRef } from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Chart from "react-apexcharts";
import { color } from "framer-motion";
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale);
ChartJS.register(LinearScale);
ChartJS.register(PointElement);
ChartJS.register(LineElement);

const LineChart = (props: any) => {
  const { data, label, title, offset } = props;
  let dataBattery
  if (title === "Battery")
    dataBattery = data?.map((value: any) => {
      // console.log (value,"=>single battery data")
      return Math.floor(value * 100) / 100
    })
  const chartRef = useRef();
  const handleClick = (event: any) => {
    console.log("handleClick");
  };

  // const datas = {
  //   labels: label,
  //   pointStyle: "",
  //   datasets: [
  //     {
  //       label: title,
  //       data: data,
  //       pointStyle: "",
  //       fill: true,
  //       backgroundColor: "#F4BE37",
  //       borderColor: "#F4BE37",
  //       borderWidth: 1,
  //       pointBorderColor: "rgb(0, 0, 0)",
  //     },
  //   ],
  // };
  // console.log(datas);

  const options = {
    chart: {
      id: "apexchart-example",
      toolbar: {
        show: false,
      }
    },

    xaxis: {

      categories: label && label.map((text: string) => {
        return text.substring(8, text.length);
      }),
      stroke: {
        color: "#F4BE37"
      },
      labels: {
        style: {
          colors: "#ffffff",
          hideOverlappingLabels: true,
          marginTop: 10,
        },
        rotate: -360,
        offsetY: offset,
      },
      tickAmount: 4,
      tickPlacement: 'on',
    },

    yaxis: {
      labels: {
        style: {
          colors: "#ffffff"
        }
      },
    },

    dataLabels: {
      enabled: false,
    },

    fill: {
      colors: ["#F4BE37", "#F4BE37", "#F4BE37"],
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.4,
        stops: [0, 50, 100],
        colorStops: [],
      },
    },
  };
  const series = [
    {
      name: title,
      data: dataBattery ? dataBattery : data,
      color: "#F4BE37",
    },
  ];

  return (
    <>
      {data && label && title && offset &&
        <Chart
          options={options}
          series={series}
          type="area"
          width={440}
          height={323}
        />
      }
    </>
  );
};
export default LineChart;
