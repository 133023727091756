import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useAuth } from "../../navigation/auth/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../loader";

function AuthUser(props: any) {
    const { authState } = props;
    const { keycloak } = useKeycloak();
    let auth = useAuth();
    let location = useLocation();
    const navigate = useNavigate();
    //@ts-ignore
    let from = location.state?.from?.pathname || "/app/barge";

    useEffect(() => {
        if (keycloak.authenticated) {
            auth.signin("Vishnu", () => {
                navigate(from, { replace: true });
            });
        } else if (authState === 'failed') {
            keycloak.login({
                redirectUri: window.location.origin + "/app/barge",
            })
        }
    }, [keycloak])
    useEffect(() => {
        console.log("authState1234", props.authState);
        if (props.authState === "loading") {
            console.log("keycloak Auth Failed");
            keycloak.login({
                redirectUri: window.location.origin + "/app/barge",
            });
        }
    }, [props.authState])
    return <div>
        {
            keycloak.authenticated ? props.children
                : <div className="w-full h-[100vh] flex flex-col justify-center">
                    <Loader />
                </div>
        }
    </div>
}
export default AuthUser;