// src/features/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThingsBoardRepoState {
  client: {};
  rowData: {};
  cabinState: {};
  data: {};
  fullData: []
  relationTable: [],
  networkError: boolean
}

const initialState: ThingsBoardRepoState = {
  client: {},
  rowData: {},
  cabinState: {},
  data: {},
  fullData: [],
  relationTable: [],
  networkError: false

};
const thingsBoardSlice = createSlice({
  name: 'thingsBoard',
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<{}>) => {
      state.client = action.payload;
    },
    setRowData: (state, action: PayloadAction<{}>) => {
      state.rowData = action.payload;
    },
    setCabinState: (state, action: PayloadAction<{}>) => {
      state.cabinState = action.payload;
    },
    setData: (state, action: PayloadAction<{}>) => {
      state.data = action.payload;
    },
    setOriginalData: (state, action: PayloadAction<[]>) => {
      state.fullData = action.payload;
    },
    setRelatonalTable: (state, action: PayloadAction<[]>) => {
      state.relationTable = action.payload;
    },
    setNetworkError: (state, action: PayloadAction<boolean>) => {
      state.networkError = action.payload;
    }
  },
});

export const { setClient, setNetworkError, setRelatonalTable, setRowData, setOriginalData, setData, setCabinState } = thingsBoardSlice.actions;
export default thingsBoardSlice.reducer;
