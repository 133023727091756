import { useEffect, useState } from "react";
import { TBClient } from "../../../config/thinksboard";
import Loader from "../../loader";
import moment from "moment-timezone";

import Table from "../../../atom/table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Search from "../../../assets/Search.svg";
import Filter from "../../../assets/Filter.svg";
import Board from "../../../assets/Board.svg";
import Tableicon from "../../../assets/Table.svg";
import BoardBlack from "../../../assets/BoardBlack.svg";
import TableBlack from "../../../assets/TableBlack.svg";
import BoardTable from "../../../layout/layout1/components/boardTable/BoardTable";
import { useSelector } from "react-redux";


function DevicesController() {
  const [tableData, setTableData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(1);

  const [pageLength, setPageLength] = useState<number>(17);
  const [calledPage, setCalledPage] = useState();
  const [activeItem, setActiveItem] = useState<any>({});

  const bargeData = useSelector((state: any) => state.thingsBoard.data);
  const fullBargeData = useSelector((state: any) => state.thingsBoard.fullData);

  useEffect(() => {
    if (Object.keys(fullBargeData).length > 0) {
      let array = JSON.parse(JSON.stringify(fullBargeData))
      Object.keys(bargeData).map((node: any) => {
        Object.keys(bargeData[node]).map((cabins: any) => {
          bargeData[node][cabins].map((devices: any, index: number) => {
            const filter = array.filter((n: any) => {
              return n.id.id === devices.id.id
            })
            if (filter[0]) {
              filter[0].leak = devices.leak
              filter[0].active = devices.active
              filter[0].websocket = devices.websocket
            }

          })
        })
      })

      setLoading(false)
      setTotalResults(array.length)
      setTableData(JSON.parse(JSON.stringify(array)))
    }
  }, [fullBargeData, bargeData])

  const [tableHeader, setTableHeader] = useState([
    {
      name: "sersor",
      displayName: "sensor",
      type: "sersor"
    },
    {
      name: "name",
      displayName: "Name",
    },
    {
      name: "type",
      displayName: "Type",
    },
    {
      name: "barge",
      displayName: "barge",
    },
    {
      name: "cabin",
      displayName: "cabin",
    },
    {
      name: "createdTime",
      displayName: "created Time",
    }
  ]);
  const navigate = useNavigate();
  const navigateTo = (nav: string) => {
    navigate(`${nav}`, { replace: true });
  };

  const setSelectedRow = async (node: any) => {
    // navigateTo("sensor/view/" + node.id.id)
    navigateTo("sensor/" + node.id.id)
    setActiveItem(node);
  }

  const handleFilteredPageNumberNav = (index: number) => {
    setPageNumber(index);
  }
  const [timeZonePicker, setTimeZonePicker] = useState<any>([
    {
      type: "timezone",
      value: moment.tz.guess(),
      default: moment.tz.guess(),
      name: "timezone",
      layout: 6,
      title: "timezone",
    },
  ]);


  const [allClicked, setAllClicked] = useState(true);

  const handleAllButtonClick = () => {
    setAllClicked(true);
  };

  const handleInactiveButtonClick = () => {
    setAllClicked(false);
  };




  return (
    <div className="gap-2   bg-[#4E515C]">

      <div className=" flex justify-between mt-2 text-sm lg:text-xl ">
        <div className="px-5">
          <button
            className={` border-2  px-[19px] py-[8px]  rounded-[4px] m-1 ${allClicked
              ? "text-[#FF9D66] bg-[#1E2742] border-[#FF9D66]"
              : "text-[black] bg-[#ffffff] border-[white]"
              }`}
            onClick={handleAllButtonClick}
          >
            <img
              className="inline me-3"
              src={allClicked ? Tableicon : TableBlack}
              alt=""
            />
            <span className="hidden md:inline font-medium text-[16px] font-lato">Table</span>
          </button>

          <button
            className={` border-2  px-[19px] py-[8px]  rounded-[4px] m-1 ${!allClicked
              ? "text-[#FF9D66] bg-[#1E2742] border-[#FF9D66]"
              : "text-[black] bg-[#ffffff] border-[white]"
              }`}
            onClick={handleInactiveButtonClick}
          >
            <img
              className="inline me-3"
              src={allClicked ? BoardBlack : Board}
              alt=""
            />
            <span className="hidden md:inline font-medium text-[16px] font-lato">Board</span>
          </button>
        </div>

        {/* <div>
          <button className="border-2  mr-[20px] px-[19px] py-[8px] rounded-[4px] text-[black] bg-[#ffffff] border-[white] m-1">
            <img className="inline me-3" src={Search} alt="" />
            <span className="hidden md:inline font-medium text-[16px] font-lato">Search</span>
          </button>
          <button className="border-2  mr-[20px] px-[19px] py-[8px]  rounded-[4px] text-[black] bg-[#ffffff] border-[white] m-1">
            <img className="inline me-3" src={Filter} alt="" />
            <span className="hidden md:inline font-medium text-[16px] font-lato">Filter</span>
          </button>
        </div> */}
      </div>




      {loading ? <div className="w-full h-[100vh] flex flex-col justify-center">
        <Loader />
      </div> :
        allClicked ? <Table tableData={tableData}
          tableHeader={tableHeader}
          setSelectedRow={setSelectedRow}
          timeZonePicker={timeZonePicker}
          pageNumber={pageNumber}
          handleFilteredPageNumberNav={handleFilteredPageNumberNav}
          totalResults={totalResults}
          pageLength={pageLength} />
          :
          tableData && <BoardTable
            tableData={tableData}
            tableHeader={tableHeader}
            setSelectedRow={setSelectedRow}
            timeZonePicker={timeZonePicker}
          />
      }
    </div>
  );
}
export default DevicesController;
