import axios from "axios";
import { getENVData } from "../config/environment";

export function getUuid(payload: any = ''): Promise<any> {
    // const currentAccount: any = window.localStorage.getItem("currentAccount");
    // if (currentAccount) {
    //     const data: any = JSON.parse(currentAccount)
    //     const uuid = data.telco_id !== "undefined" ? data.telco_id : data.tenant_id
    //     if (payload && data?.type && !payload[data.type + '_id']) {
    //         payload[data.type + '_id'] = data.uuid
    //     } else if (data.nonce) {
    //         if (payload === '') {
    //             payload = uuid
    //         } else {
    //             payload[(data.telco_id !== "undefined" ? 'telco' : 'tenant') + '_id'] = uuid
    //         }

    //     } else if (payload === '') {
    //         payload = data.uuid
    //     }
    // }
    return payload
}
export async function get(url: string): Promise<any> {    

    const result: any = await axios.get(getENVData().baseURL + url).then((response) => {
        return response.data
    }).catch(err => {
        const data = {
            status: err?.response?.status || 'not available',
            data:err?.response?.data || [],
            message: err?.message || ''
        }
        console.error("GET Error", { url: getENVData().baseURL + url, path: url, status: err?.response?.status || 'not available', code: err.code, message: err.message });
        return data
    })
    return result
}

export async function post(url: string, payload: any = {}, options: any = {}): Promise<any> {    
    // if (options?.skipHeader) {
     
    // } else {
    //     payload = getUuid(payload)
    // }
    const result: any = await axios.post(getENVData().baseURL + url, payload).then((response) => {
        return response.data
    }).catch(err => {
        const data = {
            status: err?.response?.status || 'not available',
            data:err?.response?.data || [],
            message: err?.message || ''
        }
        console.error("POST Error", { url: getENVData().baseURL + url, path: url, status: err?.response?.status || 'not available', payload: payload, code: err.code, message: err.message });
        return data
    })
    return result
}


export async function remove(url: string, payload: any = {}): Promise<any> {
    payload = getUuid(payload)
    const result: any = await axios.delete(getENVData().baseURL + url, { data: payload }).then((response) => {
        return response.data
    }).catch(err => {
        const data = {
            status: err.response.status,
            data:err.response.data,
            message: err.message
        }
        console.error("DELETE Error", { url: getENVData().baseURL + url, path: url, status: err.response.status, payload: payload, code: err.code, message: err.message });
        return data
    })
    return result
}
export async function put(url: string, payload: any = {},options: any = {}): Promise<any> {
    if (options?.skipHeader) {
     
    } else {
        payload = getUuid(payload)
    }
    const result: any = await axios.put(getENVData().baseURL + url, payload).then((response) => {
        return response.data
    }).catch(err => {
        const data = {
            status: err.response.status,
            data:err.response.data,
            message: err.message
        }
        console.error("PUT Error", { url: getENVData().baseURL + url, path: url, status: err.response.status, payload: payload, code: err.code, message: err.message });
        return data
    })
    return result
}


