import { useEffect, useState } from "react";
import { TBClient } from "../../../config/thinksboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottiefy from "../../../atom/lottie/lottie";
// import * as check from '../../../utils/lottie/check.json';
// import * as alert from '../../../utils/lottie/alert.json';
import { setRowData } from "../../../toolkit/reducers/thingsBoardSlice";
import { getTenantDevices } from "../../../services/api";
import Status from "../../../assets/status.svg";
import StatusGreen from "../../../assets/StatusGreen.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Filter from "../../../assets/Filter.svg";
import Search from "../../../assets/Search.svg";
import { CheckActiveCabin, CheckLeakCabin } from "../../../services/utilities";
import Loader from "../../loader";
import waterDrop from "../../../assets/waterDroplet.svg";

const CabinsPage = () => {
  const dispatch = useDispatch();
  const bargeData = useSelector((state: any) => state.thingsBoard.data);
  const [loading, setLoading] = useState(true);
  const cabinLength = useSelector((state: any) => state.thingsBoard.cabinState);
  useEffect(() => {
    setLoading(true)
    Object.keys(bargeData).map((barge: any) => {
      Object.keys(bargeData[barge]).map((cabin: any) => {
        Object.keys(bargeData[barge][cabin]).map((sensor: any, index: number) => {
          if (bargeData[barge][cabin][index].websocket) {
            setLoading(false)
          }
        })
      })
    })
  }, [bargeData])
  let array = Object.keys(bargeData).flatMap((barge: any) => {
    return Object.keys(bargeData[barge]).map((cabin: any) => {
      //  console.log("barge cabin in cabins",bargeData[barge][cabin])
      return {
        clength: cabinLength[barge + cabin],
        bbcl: bargeData[barge][cabin].length,
        cabin: cabin,
        barge: barge,
        sensors: bargeData[barge][cabin]
      }
    })
  })



  const navigate = useNavigate();
  const navigateTo = (nav: string) => {
    navigate(`${nav}`, { replace: true });
  };
  const setSelectedRow = async (e: any, node: any) => {
    e.stopPropagation();
    dispatch(setRowData(node));
    // navigateTo("sensor/view/" + node.id.id);
    navigateTo("sensor/" + node.id.id);
  };
  const handleCabinSensor = async (node: any) => {
    dispatch(setRowData(node));

    navigateTo(`view/${node.barge}/${node.cabin}`);
  };

  const [allClicked, setAllClicked] = useState(true);

  const handleAllButtonClick = () => {
    setAllClicked(true);
  };

  const handleInactiveButtonClick = () => {
    setAllClicked(false);
  };

  return (
    <div className="bg-[#4E515C] w-full  mb-6 px-5">
      <div className="pt-2 pb-2 px-5 flex justify-between max-w-[1435px]">
        <div>
          <button
            className={` border-2  px-[19px] py-[8px]  rounded-[4px] m-1 ${allClicked
              ? "text-[#FF9D66] bg-[#1E2742] border-[#FF9D66]"
              : "text-[black] bg-[#ffffff] border-[white]"
              }`}
            onClick={handleAllButtonClick}
          >
            All
          </button>

          <button
            className={` border-2 px-[19px] py-[8px]  rounded-[4px] m-1 ${!allClicked
              ? "text-[#FF9D66] bg-[#1E2742] border-[#FF9D66]"
              : "text-[black] bg-[#ffffff] border-[white]"
              }`}
            onClick={handleInactiveButtonClick}
          >
            Inactive
          </button>
        </div>
        {/* 
        <div className="">
          <button className="border-2   px-[19px] py-[8px] rounded-[4px] text-[black] bg-[#ffffff] border-[white] m-1">
            <img className="inline me-3" src={Search} alt="" />
            <span className="hidden md:inline"> Search </span>
          </button>
          <button className="border-2  px-[19px] py-[8px]  rounded-[4px] text-[black] bg-[#ffffff] border-[white] m-1">
            <img className="inline me-3" src={Filter} alt="" />
           <span className="hidden md:inline"> Filter </span> 
          </button>
        </div> */}
      </div>
      <div className="p-1 flex justify-center 3xl:justify-normal">
        {loading ? <div className="w-full h-[100vh] flex flex-col justify-center">
          <Loader />
        </div> :
          <div className=" p-1 max-w-[500px] 2xl:max-w-[1000px] 3xl:max-w-[1450px] ">
            {array && array.map((obj: any) => {
              if (
                allClicked === true
                  ? true
                  : obj.clength !==
                  obj.bbcl
              ) {
                return (
                  <div className=" w-[449px] h-[101px] inline-block mb-6 me-6 ">
                    <div
                      className={`${CheckActiveCabin(obj.barge, obj.cabin, bargeData) === 0
                        ? "border-[#FFFAFA] bg-[#FFFAFA]"
                        : "border-[#F88F8B] bg-[#F88F8B] "
                        } border flex justify-between  px-[24px] py-[12px] rounded-[17px] `}
                    >
                      <div className="min-w-[285px] h-[77px] text-[#1E2742]  justify-between cursor-pointer" onClick={() => { handleCabinSensor(obj); }}>
                        {obj.clength ===
                          obj.bbcl ? (
                          <span className="font-lato font-normal text-[16px] leading-[19px]">{obj.cabin.toUpperCase()} - {obj.barge.toUpperCase()}</span>
                        ) : (
                          <span className="font-bold font-lato text-[16px] leading-[19px">
                            {obj.cabin.toUpperCase()} - {obj.barge.toUpperCase()}
                          </span>
                        )}

                        <div>
                          <span className="font-bold font-lato text-[24px] pl-[5px]">
                            {obj.bbcl}
                          </span>{" "}
                          <span className="font-normal font-lato text-[14px]">Sensor</span>
                          <div className="text-sm text-[#1E2742] font-semibold font-lato ">
                            {obj.clength ===
                              obj.bbcl ? (
                              "All sensors are active"
                            ) : (
                              <div>
                                {obj.clength} out of &nbsp;
                                {obj.bbcl} sensors
                                are active
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="  max-w-[65px] self-end me-1 ">
                          {obj.sensors.map((sensor: any) => {
                            return (
                              <div
                                onClick={(e) => setSelectedRow(e, sensor)}
                                title={sensor.name}
                                className="inline-block me-2 "
                              >
                                <div className="">
                                  {/* {sensor.active ? <Lottiefy loop={false} json={check} height={32} width={32} />
                                                              : <Lottiefy loop={true} json={alert} height={32} width={32} />} */}
                                  {!sensor.leak ? null : (
                                    <img
                                      src={waterDrop}
                                      alt=""
                                      className="h-[20px] w-[20px] cursor-pointer transition-all duration-300 hover:scale-125"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div className="flex flex-row  items-end cursor-pointer pb-[6px] ">
                          <ArrowForwardIcon
                            onClick={() => {
                              handleCabinSensor(obj);
                            }}
                            className="cursor-pointer transition-all duration-300 hover:scale-125"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>



        }
      </div>
    </div>
  );
};

export default CabinsPage;


