import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTBClient } from "../../config/thinksboard";
// @ts-ignore
import tbClient from 'thingsboard-js-sdk';
import Loader from "../loader";
import axios from "axios";
import { setCabinState, setData } from "../../toolkit/reducers/thingsBoardSlice";
import { getTenantDevices } from "../../services/api";
const ThinksBoard = (props: any) => {
    const [token, setToken] = useState<any>('waiting'); //device timeseries
    const config = {
        host: "things.kutana.net",
        username: "anazeer@gmail.com",
        password: "L0ngBranch!"
    }
    const dispatch = useDispatch()
    const connect = async () => {
        const TBClient = new tbClient(config);
        const tken = await TBClient.connect(); // connect() returns token or null
        
        if (tken) {
            getTenantDevices(dispatch, tken.token);
            setTBClient(TBClient);

        }
        setToken(tken)
    }
    useEffect(() => {
        connect()
    }, [])
    return <>{token === 'waiting' ? <div className="w-full h-[100vh] flex flex-col justify-center">
        <Loader />
    </div> : token === null ? <div className="  md:px-44 h-[100vh] px-4  items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
        <div className=" w-full xl:w-1/2 relative pb-12 lg:pb-0 ">
            <div className="relative">
                <div className="absolute">
                    <div className="">
                        <h1 className="my-2 text-gray-800 font-bold text-2xl">
                            Looks like you've found the
                            doorway to the great nothing
                        </h1>
                        <p className="my-2 text-gray-800">Sorry about that! Please visit our hompage to get where you need to go.</p>
                        <button className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">Take me there!</button>
                    </div>
                </div>
                <div>
                    <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
                </div>
            </div>
        </div>
        <div>
            <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
        </div>
    </div> : props.children}</>
}
export default ThinksBoard;