import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect } from "react";

interface CrumbsState {
  brudCrubName: any[];
}

const initialState: CrumbsState = {
  brudCrubName: [],
};

const breadCrumbs = createSlice({
  name: "BreadCrumbs", // Use a different name for the slice
  initialState,
  reducers: {
    endNodes: (state, action: PayloadAction<any[]>) => {
      state.brudCrubName = action.payload
      
    },
  },
});


export const { endNodes } = breadCrumbs.actions;
export default breadCrumbs.reducer;


