
import React, { useEffect, useState } from "react"
import { TBClient } from "../../../config/thinksboard";
import GaugeChart from 'react-gauge-chart'
import BatteryGauge from 'react-battery-gauge'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Loader from "../../loader";
import * as check from '../../../utils/lottie/check.json';
import * as alert from '../../../utils/lottie/alert.json';
import * as noResult from '../../../utils/lottie/noResult.json';
import Lottiefy from "../../../atom/lottie/lottie";
import { getDeviceTimeseries } from "../../../services/api";
import LineChart from "../../../atom/Charts/lineGraph";
import Calender from "../../../assets/calendar.svg";
import Down from "../../../assets/chevron down.svg";
import Warning from "../../../assets/warning.svg"
import Filter from '../../../assets/filter.svg'
import Search from '../../../assets/search.svg'


const SensorDetails = (props: any) => {
    const { sensorId, sensorData, viewType, length } = props
    const [websocketResult, setWebsocketResult] = useState<any>({})
    const [loading, setLoading] = useState(true);
    const [websocketDatas, setWebsocketDatas] = useState<any>({})
    const [deviceHistory, setDeviceHistory] = useState<any>({})
    const rowData = useSelector((state: any) => state.thingsBoard.rowData);
    const bargeData = useSelector((state: any) => state.thingsBoard.data);
    const cabinState = useSelector((state: any) => state.thingsBoard.cabinState);
    let { id } = useParams();
    useEffect(() => {
        getDeviceHistory(sensorId ? sensorId : id)
    }, [])
    const SetData = () => {
        let data: any;
        if (id) {
            Object.keys(bargeData).map((barge: any) => {
                Object.keys(bargeData[barge]).map((cabin: any) => {
                    Object.keys(bargeData[barge][cabin]).map((sensor: any, index: number) => {
                        if (id === bargeData[barge][cabin][index].id.id) {
                            data = bargeData[barge][cabin][index]
                        }

                    })
                })
            })
            return data
        }
    }
    const rowItem = sensorData ? sensorData : SetData()
    const getDeviceHistory = async (id: any) => {
        setLoading(true)
        const mailstone = "data_temperature,data_leakage_status,data_humidity,data_battery"
        const darge = "status_battery_level,data_WATER_LEAK_STATUS"


        const data = await getDeviceTimeseries({
            device_id: sensorId ? sensorId : id,
            keys: rowItem?.type?.startsWith('Milesight') ? mailstone : darge,
            limit: 20000,
            interval: 6000000000,
            startTs: 1706080270843,
            endTs: 1706683870843

        })
        // setDeviceHistory(data)
        let labels: any = { temparature: [], status: [], humidity: [], battery: [] };
        let datas: any = { temparature: [], status: [], humidity: [], battery: [] };
        if (Object.keys(data).length > 0) {


            if (rowItem?.type?.startsWith('Milesight')) {
                data.data_temperature.map((node: any) => {
                    labels.temparature.push(new Date(node.ts).toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }))
                    datas.temparature.push(node.value)
                })
                data.data_humidity.map((node: any) => {
                    labels.humidity.push(new Date(node.ts).toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }))
                    datas.humidity.push(node.value)
                })
                data.data_battery.map((node: any) => {
                    labels.battery.push(new Date(node.ts).toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }))
                    datas.battery.push(node.value)
                })
                // data.data_leakage_status.map((node: any) => {
                //     labels.status.push(new Date(node.ts).toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }))
                //     datas.status.push(node.value)
                // })
            } else {
                data.status_battery_level.map((node: any) => {
                    labels.battery.push(new Date(node.ts).toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }))
                    datas.battery.push(node.value)
                })
                // data.data_WATER_LEAK_STATUS.map((node: any) => {
                //     labels.status.push(new Date(node.ts).toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }))
                //     datas.status.push(node.value)
                // })
            }
        }

        setDeviceHistory({ labels: labels, data: datas })
        setLoading(false)
    }

    function fromNow(date: any, nowDate: any = Date.now(), rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })) {
        const SECOND = 1000;
        const MINUTE = 60 * SECOND;
        const HOUR = 60 * MINUTE;
        const DAY = 24 * HOUR;
        const WEEK = 7 * DAY;
        const YEAR = 365 * DAY;
        const MONTH = YEAR / 12;
        const intervals = [
            { ge: YEAR, divisor: YEAR, unit: 'year' },
            { ge: MONTH, divisor: MONTH, unit: 'month' },
            { ge: WEEK, divisor: WEEK, unit: 'week' },
            { ge: DAY, divisor: DAY, unit: 'day' },
            { ge: HOUR, divisor: HOUR, unit: 'hour' },
            { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
            { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
            { ge: 0, divisor: 1, text: 'just now' },
        ];
        const now = typeof nowDate === 'object' ? nowDate.getTime() : new Date(nowDate).getTime();
        const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
        const diffAbs = Math.abs(diff);
        for (const interval of intervals) {
            if (diffAbs >= interval.ge) {
                const x = Math.round(Math.abs(diff) / interval.divisor);
                const isFuture = diff < 0;
                // @ts-ignore
                return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text;
            }
        }
    }

    return (
        <div className=" qwerty bg-[#4E515C] p-2 overflow-hidden">
            {/* <div className="text-right text-[#313638] font-normal text-base font-lato max-w-[1550px] ">
            <button className="rounded-[4px] px-2 py-1 bg-[#FFFAFA] me-2"><img className="inline me-1" src={Search} alt="Search" /><span>Search</span></button>
            <button className="rounded-[4px] px-2 py-1 bg-[#FFFAFA]"><img className="inline" src={Filter} alt="Filter" /><span >Filter</span></button>
          </div> */}


            {rowItem && rowItem.websocket && !loading ?
                Object.keys(rowItem.websocket.data).length > 0 ?
                    <><div className="flex justify-start">
                        {/* <div className="p-1">{rowItem?.active ? <Lottiefy loop={false} json={check} height={32} width={32} />
        : <Lottiefy loop={true} json={alert} height={32} width={32} />}</div>
    <div className="p-2 font-semibold">{rowItem.barge}</div>
    <div className="p-1">{rowItem?.active ? <Lottiefy loop={false} json={check} height={32} width={32} />
        : <Lottiefy loop={true} json={alert} height={32} width={32} />}</div>
    <div className="p-2 font-semibold">{rowItem.cabin}</div>
    <div className="p-1">{rowItem?.active ? <Lottiefy loop={false} json={check} height={32} width={32} />
        : <Lottiefy loop={true} json={alert} height={32} width={32} />}</div> */}
                        <div className="p-2 font-semibold text-[white] text-[18px]">{rowItem?.sersor?.toUpperCase()}</div>
                    </div><div className=" w-full">

                            {rowItem?.type?.startsWith('Milesight') ?
                                <div className=" flex flex-wrap xl:flex-row justify-around 3xl:justify-normal gap-[24px] ">
                                    <div className="  border-y  border relative border-[white] bg-[#1E2742] h-[400px] w-[400px] rounded-[12px] ">
                                        <div className="text-[16px] font-semibold px-8 py-4 text-[#FFFAFA]">TEMPERATURE</div>
                                        <div className="flex justify-center mt-8">
                                            <GaugeChart id="gauge-chart2"
                                                nrOfLevels={30}
                                                textColor="white"
                                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                                percent={rowItem?.websocket?.data.data_temperature[0][1] / 100}
                                                formatTextValue={value => rowItem?.websocket?.data.data_temperature[0][1] + " °C"}
                                                arcPadding={0.04}
                                                arcWidth={0.3} />
                                        </div>
                                        <div className="  text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center  gap-2">{fromNow(rowItem?.websocket?.data.data_temperature[0][0])}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>
                                    </div>
                                    <div className=" border-y border relative border-[white] bg-[#1E2742] h-[400px] w-[400px] rounded-[12px] ">
                                        <div className="text-[16px] font-semibold px-4 py-4 text-[#FFFAFA]">HUMIDITY</div>
                                        <div className="flex justify-center mt-8 ">
                                            <GaugeChart id="gauge-chart2"
                                                nrOfLevels={30}
                                                textColor="white"

                                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                                percent={rowItem?.websocket?.data.data_humidity[0][1] / 100}
                                                arcPadding={0.04}
                                                arcWidth={0.3} />
                                        </div>
                                        <div className=" text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center gap-2">
                                            {fromNow(rowItem?.websocket?.data.data_humidity[0][0])}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>
                                    </div>
                                    {rowItem?.websocket?.data.data_battery ? <div className="border-y border relative border-[white] bg-[#1E2742] h-[400px] w-[300px] rounded-[12px]  ">
                                        <div className="text-[16px] font-semibold  px-8 py-4 text-[#FFFAFA]">BATTERY</div>
                                        <div className="flex justify-center -translate-y-10 ">
                                            <BatteryGauge size={280} aspectRatio={0.56}
                                                className=""
                                                orientation="vertical"
                                                formatValue={value => value}
                                                customization={{
                                                    batteryBody: {
                                                        strokeWidth: 1,
                                                        cornerRadius: 6,
                                                        fill: '#D9D9D9',
                                                        strokeColor: '#D9D9D9'
                                                    },
                                                    batteryCap: {
                                                        fill: '#D9D9D9',
                                                        strokeWidth: 1,
                                                        strokeColor: '#D9D9D9',
                                                        cornerRadius: 14,
                                                        capToBodyRatio: 0.61
                                                    },
                                                    batteryMeter: {
                                                        fill: '#00D837',
                                                        lowBatteryValue: 15,
                                                        lowBatteryFill: 'red',
                                                        outerGap: 0,
                                                        noOfCells: 1,
                                                        interCellsGap: 1
                                                    },
                                                    readingText: {
                                                        lightContrastColor: '#111',
                                                        darkContrastColor: '#fff',
                                                        lowBatteryColor: 'red',
                                                        fontFamily: 'Helvetica',
                                                        fontSize: 14,
                                                        showPercentage: true,
                                                    },
                                                    chargingFlash: {
                                                        scale: undefined,
                                                        fill: 'orange',
                                                        animated: true,
                                                        animationDuration: 1000
                                                    },
                                                }} value={rowItem?.websocket?.data?.data_battery[0][1]} />
                                        </div>

                                        <div className=" mt-1 text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center gap-2 -translate-y-6">{fromNow(rowItem?.websocket?.data.data_battery[0][0])}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>
                                    </div> : null}
                                    <div className=" border-y border border-[white] bg-[#1E2742] h-[400px] w-[351px] rounded-[12px] relative ">
                                        <div className="text-[16px] font-semibold px-8 py-4 text-[#FFFAFA]">LEAK STATUS</div>
                                        <div className="flex justify-center mt-[12px]">
                                            {rowItem?.websocket?.data.data_leakage_status[0][1] === 'leak' ?
                                                <img src={Warning} alt="" className=" h-[179px] w-[179px] p-3" /> :
                                                <Lottiefy loop={false} json={check} height={200} width={200} />}
                                        </div>
                                        <div className=" text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center mt-5 gap-2">
                                            {fromNow(rowItem?.websocket?.data.data_leakage_status[0][0])}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>
                                    </div>
                                    <div className=" border-y border border-[white] bg-[#1E2742] h-[400px] w-[351px] rounded-[12px] relative ">
                                        <div className="text-[16px] font-semibold px-8 py-4 text-[#FFFAFA]">DEVICE STATUS</div>
                                        <div className="flex justify-center mt-[12px]">
                                            {!rowItem?.active ?
                                                <img src={Warning} alt="" className=" h-[179px] w-[179px] p-3" /> :
                                                <Lottiefy loop={false} json={check} height={200} width={200} />}
                                        </div>
                                        <div className=" text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center mt-5 gap-2">
                                            {fromNow(rowItem?.websocket?.latestValues.snr)}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>
                                    </div>
                                </div> :
                                <div className="flex flex-wrap gap-[4px] sm:pl-2">
                                    {rowItem?.websocket?.data.status_battery_level ? <div className=" border border-[white] bg-[#1E2742] h-[400px] w-[22%] inline-block rounded-[12px] relative ">
                                        <div className="text-[16px] text-[#FFFAFA] font-semibold px-8 py-4">BATTERY</div>
                                        <div className="flex justify-center -translate-y-10 ">
                                            <BatteryGauge size={280} aspectRatio={0.56}
                                                orientation="vertical"
                                                formatValue={value => value}
                                                customization={{
                                                    batteryBody: {
                                                        strokeWidth: 1,
                                                        cornerRadius: 6,
                                                        fill: '#00D837',
                                                        strokeColor: '#00D837'
                                                    },
                                                    batteryCap: {
                                                        fill: '#D9D9D9',
                                                        strokeWidth: 1,
                                                        strokeColor: '#D9D9D9',
                                                        cornerRadius: 14,
                                                        capToBodyRatio: 0.61
                                                    },
                                                    batteryMeter: {
                                                        fill: '#00D837',
                                                        lowBatteryValue: 15,
                                                        lowBatteryFill: 'red',
                                                        outerGap: 0,
                                                        noOfCells: 1,
                                                        interCellsGap: 1
                                                    },
                                                    readingText: {
                                                        lightContrastColor: '#111',
                                                        darkContrastColor: '#fff',
                                                        lowBatteryColor: 'red',
                                                        fontFamily: 'Helvetica',
                                                        fontSize: 14,
                                                        showPercentage: true
                                                    },
                                                    chargingFlash: {
                                                        scale: undefined,
                                                        fill: 'orange',
                                                        animated: true,
                                                        animationDuration: 1000
                                                    },
                                                }} value={rowItem?.websocket?.data?.status_battery_level[0][1]} />
                                        </div>

                                        <div className=" text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center gap-2 -translate-y-6 ">
                                            {fromNow(rowItem?.websocket?.data.status_battery_level[0][0])}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>

                                    </div> : null}

                                    <div className=" border border-[white] bg-[#1E2742] h-[400px] w-[22%] rounded-[12px] relative inline-block ">
                                        <div className="text-[16px] text-[#FFFAFA] font-semibold px-8 py-4">LEAK STATUS</div>
                                        <div className="flex justify-center mt-[12px] mb-5">
                                            {rowItem?.websocket?.data?.data_WATER_LEAK_STATUS ? rowItem?.websocket?.data?.data_WATER_LEAK_STATUS[0][1] === 'LEAK' ?
                                                <img src={Warning} alt="" />
                                                : <Lottiefy loop={false} json={check} height={200} width={200} /> :
                                                <Lottiefy loop={true} json={noResult} height={200} width={200} />}
                                        </div>
                                        <div className="   bg-[#FFFAFA] text-[black] w-max m-auto px-2 py-1 rounded-xl text-sm mt-5">
                                            {rowItem?.websocket?.data.data_WATER_LEAK_STATUS ? fromNow(rowItem?.websocket?.data.data_WATER_LEAK_STATUS[0][0]) : null}
                                            {/* <img src={Down} alt="" className="inline ms-2"/> */}
                                        </div>

                                    </div>
                                    <div className="border-y  border relative border-[white] px-5 py-3 bg-[#1E2742] h-[404px] w-[32%] rounded-[12px] 2xl:inline-block ">
                                        <h2 className=" font-bold text-[white]">BATTERY</h2>
                                        <div className=" w-[467px] h-[323px]">

                                            <LineChart title="Battery" label={deviceHistory?.labels?.battery} data={deviceHistory?.data?.battery} offset={2} />
                                        </div>


                                    </div>

                                    <div className=" border-y border border-[white] bg-[#1E2742] h-[400px] w-[22%] rounded-[12px] relative ">
                                        <div className="text-[16px] font-semibold px-8 py-4 text-[#FFFAFA]">DEVICE STATUS</div>
                                        <div className="flex justify-center mt-[12px]">
                                            {!rowItem?.active ?
                                                <img src={Warning} alt="" className=" h-[179px] w-[179px] p-3" /> :
                                                <Lottiefy loop={false} json={check} height={200} width={200} />}
                                        </div>
                                        <div className=" text-[black] text-sm bg-[#FFFAFA] w-max m-auto h-[30px] rounded-xl p-3 flex items-center mt-5 gap-2">
                                            {fromNow(rowItem?.websocket?.latestValues.snr)}
                                            {/* <img src={Down} alt=""/> */}
                                        </div>
                                    </div>
                                </div>}

                            {viewType === 'cabin' ? null : rowItem?.type?.startsWith('Milesight') &&
                                <>
                                    <div className="flex flex-wrap justify-around 3xl:justify-normal 2xl:gap-[24px] ">
                                        {deviceHistory?.labels?.temparature.length > 0 &&
                                            <div className="h-[404px] w-[491px] rounded-[14px] py-3 mt-[24px] border border-[white] bg-[#1E2742]  ">
                                                <div className="flex justify-between px-4 ">
                                                    <h2 className="text-[white]">TEMPERATURE </h2>
                                                    <img src={Calender} alt="" />
                                                </div>
                                                <div className="h-[200px] pl-2">
                                                    <LineChart title="TEMPERATURE" label={deviceHistory?.labels?.temparature} data={deviceHistory?.data?.temparature} offset={10} />
                                                </div>
                                            </div>}
                                        {deviceHistory?.labels?.humidity.length > 0 &&
                                            <div className="h-[404px] w-[491px] rounded-[14px] py-3 mt-[24px] border border-[white] bg-[#1E2742] ">
                                                <div className="flex justify-between px-4">
                                                    <h2 className="text-[white]">HUMIDITY</h2>
                                                    <img src={Calender} alt="" />
                                                </div>
                                                <div className="h-[200px]">
                                                    <LineChart title="HUMIDITY" label={deviceHistory?.labels?.humidity} data={deviceHistory?.data?.humidity} offset={10} />
                                                </div>
                                            </div>}
                                        {deviceHistory?.labels?.battery.length > 0 &&
                                            <div className="h-[404px] w-[491px] rounded-[14px] py-3 mt-[24px] border border-[white] bg-[#1E2742] ">
                                                <div className="flex justify-between px-4">
                                                    <h2 className="text-[white]">BATTERY</h2>
                                                    <img src={Calender} alt="" />
                                                </div>
                                                <div className="h-[200px]">
                                                    <LineChart title="BATTERY" label={deviceHistory?.labels?.battery} data={deviceHistory?.data?.battery} offset={10} />
                                                </div>
                                            </div>}
                                    </div>
                                    {/* <div className="flex">
                <div className="h-[320px] w-1/3 p-8 border-b border-primary-alpha-20 border-r">
                    <LineChart title="Battery" label={deviceHistory?.labels?.battery} data={deviceHistory?.data?.battery} />
                </div>
                 <div className="h-[320px] w-1/2 py-8">
            <LineChart title="Status" label={deviceHistory.labels.status} data={deviceHistory.data.status} />
        </div>
            </div> */}
                                </>}
                        </div></>
                    : <div className=""><div className={`flex items-center justify-center ${id || length == 1 ? "min-h-[40vh]" : ""} `} >
                        <Lottiefy loop={true} json={noResult} height={200} width={200} />

                    </div>
                        <div className="text-center text-[#ffffff] ">
                            No Result Found
                        </div>
                    </div>
                : <div className=""><div className={`flex items-center justify-center ${id || length == 1 ? "min-h-[80vh]" : ""} `} >
                    <Loader />
                </div>
                </div>}
        </div >
    )
}

export default SensorDetails