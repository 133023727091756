// counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EmbeddedItemsState {
  EmbeddedItem: any[];
  menu:any[],
  submenu:any
}

const initialState: EmbeddedItemsState = {
    EmbeddedItem: [],
    menu:[],
    submenu:[],
};

const embeddedItems = createSlice({
  name: 'EmbeddedItems',
  initialState,
  reducers: {
   setEmbeddedItems :(state,action:PayloadAction<any[]>)=>{
    state.EmbeddedItem = action.payload
   },
   setMenu :(state,action:PayloadAction<any[]>)=>{
    state.menu = action.payload
   },
   setSubmenu :(state,action:PayloadAction<any[]>)=>{
    state.submenu = action.payload
   }
  },
});

export const { setEmbeddedItems , setMenu , setSubmenu} = embeddedItems.actions;
export default embeddedItems.reducer;
