import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import RedSensor from '../../../assets/red-sensor.svg'
import GreenSensor from '../../../assets/green-sensor.svg'
import waterDrop from '../../../assets/waterDroplet.svg'
import Arrow from '../../../assets/u-arrow-right.svg'
import { setRowData } from '../../../toolkit/reducers/thingsBoardSlice';
import Loader from "../../loader";
import _ from 'lodash';
import { CheckActiveCabin, CheckLeak, CheckLeakCabin } from '../../../services/utilities';
function ViewBarge() {

    const { pathname } = useLocation();
    const [bargeName, setBargeName] = useState<any>("");
    const [loader, setLoader] = useState<any>(true);
    const allBargeData = useSelector((state: any) => state.thingsBoard.data);
    const cabinState = useSelector((state: any) => state.thingsBoard.cabinState);
    const dispatch = useDispatch();


    const navigate = useNavigate();
    const navigateTo = (nav: string) => {
        navigate(`${nav}`, { replace: true });
    };

    useEffect(() => {
        let pathArray = pathname.split('/');
        let lastNameInPath = pathArray[pathArray.length - 1] === "" ? pathArray[pathArray.length - 2] : pathArray[pathArray.length - 1];
        let barge = lastNameInPath.includes("%20") ? lastNameInPath.replace(/%20/g, " ") : lastNameInPath;
        setBargeName(barge)
        if (!_.isEmpty(allBargeData)) {
            setLoader(false)
        }
    }, [pathname, allBargeData, cabinState])

    const setSelectedRow = async (node: any) => {
        dispatch(setRowData(node))
        // navigateTo("sensor/view/" + node.id.id)
        navigateTo("sensor/" + node.id.id)
        // setActiveItem(node);
    }
    const handleCabinSensor = async (node: any) => {
        dispatch(setRowData(node))

        navigateTo(`view/${node}`)
    }

    return (
        <div >
            <div className='p-4 flex flex-wrap '>
                {loader ? <div className='flex justify-center items-center min-h-[80vh] w-full'><Loader /></div>

                    : Object.keys(allBargeData[bargeName]).map((cabin: any) => {
                        return (
                            <div className='w-[342px]  m-1 p-1'>
                                <div className={`${CheckActiveCabin(bargeName, cabin, allBargeData) === 0 ? 'bg-[#FFFAFA]' : 'bg-[#F88F8B]'}  px-4 flex justify-between text-[#1E2742] font-lato rounded-[17px] shadow-3xl`}

                                >
                                    <div className='py-2 cursor-pointer' onClick={() => handleCabinSensor(cabin)}>
                                        <h4 className='font-bold text-base '>{cabin.toUpperCase()}</h4>
                                        <h4 className='font-semibold text-[42px]'>{allBargeData[bargeName][cabin].length} <span className='font-normal text-sm'>Sensor</span></h4>
                                        {cabinState[bargeName + cabin] === allBargeData[bargeName][cabin].length ?
                                            <h4 className='font-semibold text-sm'>All sensors active</h4> :
                                            <h4 className='font-semibold text-sm'>{cabinState[bargeName + cabin]} out of {allBargeData[bargeName][cabin].length} sensers is active</h4>
                                        }
                                    </div>
                                    <div className='flex'>
                                        <div className='max-w-[70px] self-end me-2'>
                                            {allBargeData[bargeName][cabin].map((sensor: any) => {
                                                // console.log("sensor in viewBarge",sensor)
                                                return (
                                                    <div className='inline-block m-1 c' onClick={() => setSelectedRow(sensor)} title={sensor.name} >
                                                        {sensor.leak ? <img className="cursor-pointer transition-all duration-300 hover:scale-125 h-[20px] w-[20px]" src={waterDrop} alt="Red Sensor" /> : null
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='self-end pb-2 text-lg' onClick={() => handleCabinSensor(cabin)}>
                                            <img className="transition-all duration-300 hover:scale-125 cursor-pointer" src={Arrow} alt="Right Arrow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ViewBarge