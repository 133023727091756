import moment from "moment";
import React from "react";
import View from "../../../../assets/view.svg";
import Time from "../../../../assets/time.svg";
import Wifi from "../../../../assets/wifi.svg";
import Dot from "../../../../assets/white-dot.svg";
import { useDispatch } from "react-redux";
import { setRowData } from "../../../../toolkit/reducers/thingsBoardSlice";
import waterDrop from '../../../../assets/waterDroplet.svg';

interface BoardProps {
  tableData: any;
  tableHeader: any;
  setSelectedRow: any;
  timeZonePicker: any;
}

function BoardTable({
  tableData,
  tableHeader,
  setSelectedRow,
  timeZonePicker,
}: BoardProps) {
  // console.log("tableDAta in board", tableData);
  const dispatch = useDispatch();

  const handleUtc = (dateValue: any) => {
    let utcTime = moment.utc(dateValue);
    let Local = utcTime.format("YYYY-MM-DD HH:mm:ss");
    if (timeZonePicker[0]) {
      Local = timeZonePicker[0].value;
    }
    let LocalTime = moment(utcTime, Local).format("YYYY-MM-DD HH:mm:ss");
    return LocalTime;
  };

  const SetRedux = async (node: any) => {
    dispatch(setRowData(node));
    setSelectedRow(node);
  };

  return (
    <div className=" ">
      <div className=" lg:w-[95%] m-auto py-4 flex flex-wrap justify-center 2xl:justify-start">
        {tableData.map((node: any) => {
          return (
            <div className=" font-lato w-[472px] grow 3xl:grow-0 m-2">
              <div
                className=" bg-[#1E2742] p-3 rounded-xl shadow-3xl"
                onClick={() => SetRedux(node)}
              >
                <div
                  className={`  ${node.active ? "bg-[#FFFAFA]" : "bg-[#FF6C66]"
                    }  rounded-[35px] py-1 px-[10px] font-bold text-lg flex justify-between`}
                >
                  <label
                    className={` flex justify-between ${node.active ? "text-[#313638]" : "text-[#FFFAFA]"
                      }`}
                  >
                    {node.barge} - {node.cabin.toUpperCase()}  <div className="ml-2 p-1">{node["leak"] ? <img src={waterDrop} alt="" className=" h-[20px] w-[20px]" /> : null}</div>
                  </label>

                  <img
                    className="inline me-3 transition-all duration-300 hover:scale-125 cursor-pointer"
                    src={View}
                    alt="View"
                  />
                </div>

                <div className="flex justify-between mt-2">
                  <div className="">
                    <img
                      className="inline me-3 pb-[2px]"
                      src={Wifi}
                      alt="Wifi"
                    />

                    <label className="font-semibold text-sm pt-2 text-[#FFFAFA]">
                      {node.sersor}
                    </label>
                  </div>

                  <div className="bg-[#13192F] rounded-[14px] px-3 ">
                    <img
                      className="inline me-3 ms-1 pb-[2px]"
                      src={Time}
                      alt="Time"
                    />

                    <label className="font-semibold text-xs text-[#FFFAFA]">
                      {handleUtc(node.createdTime)}
                    </label>
                  </div>
                </div>

                <div className="flex justify-between mt-1 px-1">
                  <div className="">
                    <img
                      className="inline me-2  pb-[2px]"
                      src={Dot}
                      alt="Dot"
                    />

                    <label className="font-semibold text-sm  text-[#FFFAFA]">
                      {node.cabin}
                    </label>
                  </div>

                  <div className=" ">
                    <img
                      className="inline me-2 pb-[2px] "
                      src={Dot}
                      alt="Dot"
                    />

                    <label className="font-semibold text-sm text-[#FFFAFA]">
                      {node.deviceProfileName}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BoardTable;
