import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useApp } from "../appContext";
import EmbededItems from "../assets/embeded_items.json";
import { useEffect } from "react";
import SensorDetails from "../containers/userMenus/Sensors/sensor";
import Layout1 from "../layout/layout1/layout1";
import Layout2 from "../layout/layout2/layout2";
import DevicesController from "../containers/userMenus/devices/devices";
import CabinsPage from "../containers/userMenus/Cabins/cabins";
import CabinSensorDetails from "../containers/userMenus/Sensors/cabinSensors";
import CabinSensors from "../containers/userMenus/Sensors/cabinSensors";
import ViewBarge from "../containers/userMenus/ViewBarge/ViewBarge";
const Login = React.lazy(() => import("../containers/auth/login/login"));

const WelcomePage = React.lazy(
  () => import("../containers/userMenus/welcomePage/WelcomePage")
);
const RouterConfig = () => {
  const appContext = useApp();
  const { layout } = appContext;
  const Layout = layout === "layout1" ? <Layout1 /> : <Layout2 />;
  return (
    <>
      <Routes>
        <Route element={Layout}>
          <Route
            path="app/auth/login"
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="app/"
            element={
              <React.Suspense fallback={<></>}>
                <WelcomePage />
              </React.Suspense>
            }
          />
          <Route
            path="app/sensor/view/:id"
            element={
              <React.Suspense fallback={<></>}>
                <SensorDetails />
              </React.Suspense>
            }
          />
          <Route
            path="app/devices/sensor/:id"
            element={
              <React.Suspense fallback={<></>}>
                <SensorDetails />
              </React.Suspense>
            }
          />
          {/* <Route
            path="app/cabins/view"
            element={
              <React.Suspense fallback={<></>}>
                <CabinSensors />
              </React.Suspense>
            }
          /> */}
          <Route
            path="app/barge/:barge/view/:cabins"
            element={
              <React.Suspense fallback={<></>}>
                <CabinSensors />
              </React.Suspense>
            }
          />
          <Route
            path="app/:name/view/:barge/:cabins"
            element={
              <React.Suspense fallback={<></>}>
                <CabinSensors />
              </React.Suspense>
            }
          />
          <Route
            path="app/barge/sensor/:id"
            element={
              <React.Suspense fallback={<></>}>
                <SensorDetails />
              </React.Suspense>
            }
          />
          <Route
            path="app/barge/:name/sensor/:id"
            element={
              <React.Suspense fallback={<></>}>
                <SensorDetails />
              </React.Suspense>
            }
          />
          <Route
            path="app/:name/sensor/:id"
            element={
              <React.Suspense fallback={<></>}>
                <SensorDetails />
              </React.Suspense>
            }
          />
          <Route
            path="app/cabins/sensor/:id"
            element={
              <React.Suspense fallback={<></>}>
                <SensorDetails />
              </React.Suspense>
            }
          />
          <Route
            path="app/barge"
            element={
              <React.Suspense fallback={<></>}>
                <WelcomePage />
              </React.Suspense>
            }
          />
          <Route
            path="app/barge/:name"
            element={
              <React.Suspense fallback={<WelcomePage />}>
                <ViewBarge />
              </React.Suspense>
            }
          />
          <Route
            path="app/:name"
            element={
              <React.Suspense fallback={<></>}>
                <ViewBarge />
              </React.Suspense>
            }
          />
          <Route
            path="app/cabins"
            element={
              <React.Suspense fallback={<></>}>
                <CabinsPage />
              </React.Suspense>
            }
          />
          <Route
            path="app/devices"
            element={
              <React.Suspense fallback={<></>}>
                <DevicesController />
              </React.Suspense>
            }
          />
          <Route
            path="app/settings"
            element={
              <React.Suspense fallback={<></>}>
                Settings
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};
export default RouterConfig;
